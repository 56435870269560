import React, { useState} from "react";
import Container from "./components/Container";
import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function USetting() {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  if (!isLoggedIn) {
    navigate("/login");
    return null;
  }

  const handleLogout = () => {
    navigate("/logout");
  };
  const handleSetting = () => {
    navigate("/dashboard/settings");
  };
  const handleManageProduct = () => {
    navigate("/dashboard/products");
  };
  const handleManageCategories = () => {
    navigate("/dashboard/cats");
  };
  const handleManageSubcategories = () => {
    navigate("/dashboard/subcats");
  };
  const handledashboard = () => {
    navigate("/dashboard");
  };
  const handleOrders = () => {
    navigate("/dashboard/orders");
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      setTimeout(() => {
        setErrorMessage(""); // Clear error message after 3 seconds
      }, 3000);
      return;
    }

    try {
      const userId = 0;
      const response = await axios.post(
        "http://api.massgu.com/api/auth/changepassword",
        { currentPassword, newPassword, userId }
      );
      console.log(response.message);
      setErrorMessage("Passwords Changed Successfully");
      setTimeout(() => {
        setErrorMessage(""); // Clear success message after 3 seconds
      }, 3000);
    } catch (error) {
      console.error("Error changing password:", error);
      setErrorMessage("Error changing password. Please try again later.");
      setTimeout(() => {
        setErrorMessage(""); // Clear error message after 3 seconds
      }, 3000);
    }
  };

  return (
    <div className="bg-gray-100">
      <header className="py-4 px-24 bg-customC text-white">
        <a href="/dashboard">
          <h1 className="text-2xl font-bold text-center">My Admin</h1>
        </a>
      </header>
      <Container>
        <section className="grid grid-cols-12 gap-2">
          <div className="bg-white p-4 rounded-md col-span-3 md:col-span-2">
            <ul>
              <li>
                <button
                  onClick={handledashboard}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Dashboard
                </button>
              </li>
              <li>
                <button
                  onClick={handleManageProduct}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Products
                </button>
              </li>
              <li>
                <button
                  onClick={handleManageSubcategories}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Subcategories
                </button>
              </li>
              <li>
                <button
                  onClick={handleManageCategories}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Categories
                </button>
              </li>
              <li>
                <button className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm">
                  Manage Users
                </button>
              </li>
              <li>
                <button
                  onClick={handleOrders}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Order
                </button>
              </li>
              <li>
                <button
                  onClick={handleSetting}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Setting
                </button>
              </li>
              <li>
                <button
                  onClick={handleLogout}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
          <div
            className="bg-white p-4 rounded-md col-span-9 md:col-span-10"
          >
            <div className="bg-customC text-white text-2xl font-bold text-center py-2 shadow">
              Settings
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                className="col-span-full md:col-span-2 my-2 rounded-md border shadow p-2 overflow-scroll"
              >
                <h1 className="text-customA font-bold">Change Password</h1>
                {errorMessage && (
                  <p className="text-red-500">{errorMessage}</p>
                )}
                <form onSubmit={handleChangePassword}>
                  <div className="space-y-4">
                    <input
                      placeholder="Enter Current Password"
                      type="password"
                      className="px-2 py-1 bg-white border outline-customC  rounded-md block"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    <input
                      placeholder="Enter New Password"
                      type="password"
                      className="px-2 py-1 bg-white border outline-customC  rounded-md block"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <input
                      placeholder="Confirm New Password"
                      type="password"
                      className="px-2 py-1 bg-white border outline-customC  rounded-md block"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <input
                      type="submit"
                      value="Change Password"
                      className="px-2 py-1 bg-customC text-white rounded-md hover:bg-customA block"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
}

export default USetting;
