import React, { useState, useEffect } from "react";
import axios from "axios";

function AddCat() {
  const [categoryName, setCategoryName] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const handleAddCategory = async () => {
    try {
      const response = await axios.post(
        "http://api.massgu.com/api/auth/categories",
        { name: categoryName }
      );
      if (response.status === 201) {
        setAlertMessage("Category created successfully");
        setIsError(false);
        window.location.reload();
        setCategoryName("");
      }
    } catch (error) {
      console.error("Error creating category:", error);
      setAlertMessage("Error creating category: " + error.message);
      setIsError(true);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAlertMessage("");
    }, 3000);
    return () => clearTimeout(timeout);
  }, [alertMessage]);

  return (
    <div
    className="col-span-full rounded-md border shadow p-2">
      <h1
      className="text-customA font-bold">Add Category</h1>
      {alertMessage && (
        <div
          className={`alert ${isError ? "alert-danger" : "alert-success"}`}
          role="alert"
        >
          {alertMessage}
        </div>
      )}
      <input
        type="text"
        placeholder="Category Name"
        className="border py-1 px-2 rounded-md outline-customA bg-white my-2 inline-block"
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
      />
      <button
        className="py-1 px-2 bg-customA text-white rounded-md"
        onClick={handleAddCategory}
      >
        +
      </button>
    </div>
  );
}

export default AddCat;
