import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function useAuth() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // 
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const login = () => {
    
    localStorage.setItem("token", "your_jwt_token");
    setIsLoggedIn(true);

    setTimeout(() => {
      navigate("/dashboard"); 
    }, 1000); 
  };

  const logout = () => {    
    localStorage.removeItem("token");
    setIsLoggedIn(false);

    setTimeout(() => {
      navigate("/login"); 
    }, 1000); 
  };

  return { isLoggedIn, login, logout };
}

export default useAuth;
