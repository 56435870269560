import React,{useState} from "react";
import Container from "./components/Container";
import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";
import AddSubcat from "./components/AddSubcat";
import ListSubcat from "./components/ListSubcat";

function ManageSubCategories() {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [dropDownClass, setdropDownClass] = useState("hidden");

  if (!isLoggedIn) {
    navigate("/login");
    return null;
  }

  const handleLogout = () => {
    navigate("/logout");
  };
  const handleOrders = () => {
    navigate("/dashboard/orders");
  };
  const handleSetting = () => {
    navigate("/dashboard/settings");
  };
  const handleManageProduct = () => {
    navigate("/dashboard/products");
  };
  const handleManageCategories = () => {
    navigate("/dashboard/cats");
  };
  const handleManageSubcategories = () => {
    navigate("/dashboard/subcats");
  };
  const handledashboard = () => {
    navigate("/dashboard");
  };
  const handleFilterDropDown = () => {
    if (dropDownClass === "hidden") {
      setdropDownClass("block");
    } else {
      setdropDownClass("hidden");
    }
  };
  return (
    <div className="bg-gray-100">
      <header className="py-4 px-24 bg-customC text-white">
        <a href="/dashboard">
          <h1 className="text-2xl font-bold text-center">My Admin</h1>
        </a>
      </header>
      <Container>
        <section className="grid grid-cols-12 gap-2">
          <div className="col-span-full py-2 md:hidden">
            <button
            onClick={() => handleFilterDropDown()}
            className="bg-customC text-white px-4 py-2 rounded-md">
              <span className="fa fa-navicon"></span>
            </button>
          </div>
          <div className={
              "bg-white p-4 rounded-md col-span-full md:col-span-2 md:block " +
              dropDownClass
            }>
            <ul>
               <li>
                <button
                  onClick={handledashboard}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Dashboard
                </button>
              </li>
              <li>
                <button
                  onClick={handleManageProduct}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Products
                </button>
              </li>
              <li>
                <button
                  onClick={handleManageSubcategories}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Subcategories
                </button>
              </li>
              <li>
                <button
                  onClick={handleManageCategories}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Categories
                </button>
              </li>
              <li>
                <button className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm">
                  Manage Users
                </button>
              </li>
              <li>
                <button
                  onClick={handleOrders}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Order
                </button>
              </li>
              <li>
                <button
                  onClick={handleSetting}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Setting
                </button>
              </li>
              <li>
                <button
                  onClick={handleLogout}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
          <div className="bg-white p-4 rounded-md col-span-full md:col-span-10">
            <div className="bg-customC text-white text-2xl font-bold text-center py-2 shadow">
              Manage Sub-Categories
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <AddSubcat />
              <div className="my-2 rounded-md border shadow p-2 col-span-full md:col-span-2">
                <h1 className="text-customA font-bold text-center">
                  List of Sub-Categories
                </h1>
                <div className="overflow-x-auto">
                  <ListSubcat />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
}

export default ManageSubCategories;
