import React, { useState, useEffect, useRef } from "react";
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const QuillEditor = ({ setOutput, preDefinedContent }) => {
  const [quill, setQuill] = useState(null);
  const quillRef = useRef(null);

  useEffect(() => {
    if (!quillRef.current) return;

    const newQuill = new Quill(quillRef.current, {
      theme: 'snow',
      placeholder: "Description",
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ['link'],
          [{ 'color': [] }],
          [{ 'background': [] }],
          ['emoji']
        ]
      }
    });

    newQuill.on('text-change', () => {
      const content = newQuill.root.innerHTML;
      setOutput(content);
    });

    if (newQuill && newQuill.root) {
      newQuill.root.innerHTML = preDefinedContent; // Set initial content programmatically
    }

    setQuill(newQuill);

    return () => {
      if (newQuill) {
        newQuill.off('text-change');
        newQuill.root.parentNode.removeChild(newQuill.root);
        setQuill(null);
      }
    };
  }, [setOutput, preDefinedContent]); // Include preDefinedContent in the dependency array

  return (
    <div
      id="editor"
      ref={quillRef}
      style={{ height: '100px', margin: '10px 0' }}
    />
  );
};

export default QuillEditor;
