import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import Container from "./components/Container";
import axios from "axios";

function Cart({ removeFromCart, editCartItem, cartItems }) {
  const [alertMessage, setAlertMessage] = useState("");
  const [isError, setIsError] = useState(false);


  useEffect(() => {
    const timeout = setTimeout(() => {
      setAlertMessage("");
    }, 3000);
    return () => clearTimeout(timeout);
  }, [alertMessage]);


  const handleIncreaseQuantity = (index) => {
    editCartItem(index, "increase");
  };

  const handleDecreaseQuantity = (index) => {
    editCartItem(index, "decrease");
  };

  const calculateTotalPrice = (cartItems) => {
    let totalPrice = 0;
    cartItems.forEach((item) => {
      totalPrice += parseInt(item.current_price) * parseInt(item.quantity);
    });
    return totalPrice;
  };

  const totalPrice = calculateTotalPrice(cartItems);
  const handleSearch = (keyword) => {
  };

  const handleAddOrder = async (cartItems, totalPrice, orderId) => {
    try {
      const response = await axios.post(
        "http://api.massgu.com/api/auth/orders",
        {
          cartItems: cartItems,
          totalPrice: totalPrice,
          orderId: orderId // Pass the orderId to the API request
        }
      );
      if (response.status === 201) {
        setAlertMessage('Your order placed successfully Note order-id: '+orderId);
        setIsError(false);
        console.log("order placed ok");
        //window.location.reload();
      }
    } catch (error) {
      console.error("Error creating order:", error);
      // setAlertMessage("Error creating order: " + error.message);
      // setIsError(true);
    }
  };

  function generateUniqueId() {
    const randomPart = Math.random().toString(36).substr(2, 9);
    const timestamp = Date.now().toString(36);
    const uniqueId = randomPart + timestamp;
    return uniqueId;
  }

  const generatePDFLocal = () => {
    const uid = generateUniqueId();
    handleAddOrder(cartItems,totalPrice,uid);
    import('./splitting/generatePDF')
      .then(({ generatePDF }) => {
        return generatePDF(cartItems, totalPrice, uid);
      })
      .catch(err => {
        console.log("failed to load module");
      });
  };

  const handelcartremove = (index) => {
    try {
      removeFromCart(index);
      window.location.reload();
    } catch (error) {
      console.error("cant remove cart item");
    }
  }

  return (
    <div className="bg-gray-100">
      <Header handleSearch={handleSearch} cartItems={cartItems} />
      <Container>
        <ul
        className="w-full flex justify-start items-center py-2 border-b-2 space-x-2 bg-white px-2">
          <li>
            <a href="/">Home</a>
          </li>
          <li>/</li>
          <li className="text-customC">
            <a href="/">Cart</a>
          </li>
        </ul>
      </Container>
      <Container>
        <div className="md:py-10 overflow-scroll">
          {alertMessage && (
            <div
            className={`alert ${isError ? "alert-danger text-center text-red-500 font-bold" : "alert-success text-center text-green-500 font-bold"}`}
                role="alert"
              >
                {alertMessage}
              </div>
            )}
          <table className="table-auto w-full border-collapse border border-customA">
            <caption className="my-2 py-4 bg-customC text-white font-bold">
              MY CART
            </caption>
            <thead>
              <tr>
                <th className="px-4 py-2 bg-customC text-gray-100 text-left">
                  Image
                </th>
                <th className="px-4 py-2 bg-customC text-gray-100 text-left">
                  Title
                </th>
                <th className="px-4 py-2 bg-customC text-gray-100 text-left">
                  Category
                </th>
                <th className="px-4 py-2 bg-customC text-gray-100 text-left">
                  Brand
                </th>
                <th className="px-4 py-2 bg-customC text-gray-100 text-left">
                  Price
                </th>
                <th className="px-4 py-2 bg-customC text-gray-100 text-left">
                  Quantity
                </th>
                <th className="px-4 py-2 bg-customC text-gray-100 text-left">
                  Remove
                </th>
              </tr>
            </thead>
            <tbody>
              {cartItems.map((item, index) => (
                <tr
                  key={item.id}
                  className="border-customA hover:bg-customD text-center"
                >
                  <td
                    key={index}
                    className="border lg:px-4 lg:py-2 min-w-[150px]"
                  >
                    {item.image.split(',:,').map((image, index) => {
                      if (index === 0) {
                        return (
                        <img
                        key={index}
                        className="w-24 md:w-32 lg:w-40"
                        src={`http://api.massgu.com/images/${image.trim()}`}
                        alt={`Product ${index + 1}`}/>
                        );
                      }
                      return null;
                      })}
                    
                  </td>
                  <td
                    key={index}
                    className="border lg:px-4 lg:py-2 min-w-[200px]"
                  >
                    {item.title}
                  </td>
                  <td
                    key={index}
                    className="border lg:px-4 lg:py-2  min-w-[150px]"
                  >
                    {item.subcategory_name}
                  </td>
                  <td
                    key={index}
                    className="border lg:px-4 lg:py-2  min-w-[150px]"
                  >
                    {item.brand}
                  </td>
                  <td
                    key={index}
                    className="border lg:px-4 lg:py-2  min-w-[100px]"
                  >
                    {item.current_price}
                  </td>
                  <td
                    key={index}
                    className="border lg:px-4 lg:py-2 text-slate-700 space-x-2  min-w-[150px]"
                  >
                    <button
                      className="bg-customC hover:bg-customB px-2 text-white"
                      onClick={() => handleDecreaseQuantity(index)}
                    >
                      -
                    </button>{" "}
                    <span>{item.quantity}</span>{" "}
                    <button
                      onClick={() => handleIncreaseQuantity(index)}
                      className="bg-customC hover:bg-customB px-2 text-white"
                    >
                      +
                    </button>
                  </td>
                  <td
                    key={index}
                    className="border lg:px-4 lg:py-2 text-red-500 cursor-pointer w-10"
                    onClick={() => handelcartremove(index)}
                  >
                    <span className="fa fa-trash"></span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="bg-white w-full shadow text-center text-customA py-1 font-semibold">
            Total RS: {totalPrice}
          </div>
          <div className="space-x-2 w-full text-center">
            <button
              className="px-4 py-1 bg-customC text-white my-2 hover:bg-customA"
              onClick={generatePDFLocal}
            >
              Generate Reciept
            </button>
          </div>
        </div>
      </Container>
      <Container param="bg-[#2c0c24]">
        <section className="py-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 bg-[#2c0c24]">
          <div className="">
            <div className="text-customA text-xl font-bold text-center sm:text-left">
              <img src="/resources/logo2.png" alt="logo"/>
            </div>
            <p className="text-gray-100 text-sm py-2 text-center sm:text-left">
              MASSGU Fancy CHOICE is mainly dealing as wholesaler in mobile &
              accessories / CCTV Cameras & Services and other electronics and
              General Items in Seni Gumbat, Kohat. Massgu Fancy choice is the
              best choice for individuals, families & resellers for it's quality
              products and COD based deals.
            </p>
          </div>
          <div className="">
            <h3 className="text-base text-gray-100 font-semibold text-center">
              Follow us on
            </h3>
            <ul className="py-2 text-sm text-gray-100 text-center space-y-1">
              <li>
                <a href="https://www.youtube.com/@MassguFancyChoice">
                  <span className="fa fa-youtube"></span> Youtube
                </a>
              </li>
              <li>
                <a href="https://web.facebook.com/massgufancychoice/">
                  <span className="fa fa-facebook"></span> Facebook
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/massgufancychoice">
                  <span className="fa fa-instagram"></span> Instagram
                </a>
              </li>
              <li>
                <a href="https://wwww.tiktok.com/@massgufancychoice?lang=en">
                  <span className="fa fa-music"></span> Tiktok
                </a>
              </li>
              <li>
                <a href="https://www.pinterest.com/massgufancychoice/">
                  <span className="fa fa-pinterest"></span> Pinterest
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <h3 className="text-base text-gray-100 font-semibold text-center">
              Contact
            </h3>
            <ul className="py-2 text-sm text-gray-100 text-center space-y-1">
              {/* <li>
                  <p>Phone: 0000000000</p>
                </li>
                <li>
                  <p>email: test@mail.com</p>
                </li> */}
              <li>
                <a href="https://wa.me/+923324903770">
                  <span className="fa fa-whatsapp"></span> +923324903770
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <h3 className="text-base text-gray-100 font-semibold text-center">
              Address
            </h3>
            <ul className="py-2 text-sm text-gray-100 text-center space-y-1">
              <li>
                <p>
                  Address: Main Rawalpindi Road, Seni Gumbat Bazar, Nearby PSO
                  "Petrol Station", Sign Board: Massgu Fancy Choice.
                </p>
              </li>
            </ul>
          </div>
        </section>
      </Container>
    </div>
  );
}

export default Cart;
