import React, { useState, useEffect } from "react";
import axios from "axios";

function ListProTypes() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        "http://api.massgu.com/api/auth/products"
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleEditProductSubmit = async (event, Type) => {
    event.preventDefault();
    const selectedValue = event.target.value;
    try {
      const formData = new FormData();
      formData.append("psize", selectedValue);
      formData.append("Type", Type); // Append Type to the form data

      const response = await axios.put(
        `http://api.massgu.com/api/auth/products/updatetype`,
        formData,
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        console.log(response.message);
        fetchProducts();
      }
    } catch (error) {
      console.error("Error updating product Type:", error);
    }
  };

  const uniqueTypes = Array.from(new Set(products.map((product) => product.ptype)));

  return (
    <div>
      <table className="table-auto w-full border-collapse border border-customA">
        <thead>
          <tr>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Type
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Featuring
            </th>
          </tr>
        </thead>
        <tbody>
          {uniqueTypes.map((Type, index) => (
            <tr key={index} id={"product_id_" + index} className="border-customA hover:bg-customD">
              <td className="border px-4 py-2 w-fit">{Type}</td>
              <td className="border px-4 py-2">
                <select
                  value={products.some((pro) => {
                    return pro.ptype === Type && parseInt(pro.type_feature) === 1;
                  }) ? 1 : 0}
                  className={products.some((pro) => {
                    return pro.ptype === Type && parseInt(pro.type_feature) === 1;
                  }) ? "w-1/2 bg-green-300" : "w-1/2 bg-red-300"}
                  onChange={(event) => handleEditProductSubmit(event, Type)}>
                  <option disabled>Show Type on home page</option>
                  <option value={0}>No</option>
                  <option value={1}>Yes</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ListProTypes;
