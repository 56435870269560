import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Container from "./components/Container";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Product({ addToCart, cartItems, editCartItem }) {
  const [product, setProduct] = useState({});
  const [isHoveredfb, setIsHoveredfb] = useState(false);
  const [isHoveredin, setIsHoveredin] = useState(false);
  const [isHoveredwa, setIsHoveredwa] = useState(false);
  const [img, setImg] = useState("");
  const [getQuantity, setQuantity] = useState(1);
  const { id } = useParams();
  const { category } = useParams();
  const currentURL = window.location.href;

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const productResponse = await axios.get(
          `http://api.massgu.com/api/auth/products/${id}`
        );
        setProduct(productResponse.data);
        setImg(productResponse.data.image);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchItems();
  }, [id]);

  function sendWhatsAppMessage() {
    
    const phoneNumber = "+923324903770"; 
    const message = encodeURIComponent(
      `MASSGU FANCY CHOICE\nProduct Name: ${product.title}\nProduct Category: ${product.subcategory_name}\nProduct Brand: ${product.brand}\nProduct Color: ${product.color}\nProduct Price: ${product.current_price}\nProduct Link: ${currentURL}`
    );

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

    window.open(whatsappUrl, "_blank");
  }

  const handleSocialShare = (platform) => {
    switch (platform) {
      case "fb":
        const msg = encodeURIComponent(
          `Product Name: ${product.title}\nProduct Category: ${product.subcategory_name}\nProduct Brand: ${product.brand}\nProduct Color: ${product.color}\nProduct Price: ${product.current_price}`
        );

        const urlToShare = encodeURIComponent(
          `http://massgu.com/category/${product.subcategory_name}/${product.title}/${product.id}`
        );

        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}&quote=${msg}`;

        window.open(facebookUrl, "_blank");

        break;
      case "in":
        const imageUrl = encodeURIComponent(
          `http://api.massgu.com/images/+${product.image}`
        );

        const caption = encodeURIComponent(
          `Product Name: ${product.title}\nProduct Category: ${product.subcategory_name}\nProduct Brand: ${product.brand}\nProduct Color: ${product.color}\nProduct Price: ${product.current_price}`
        );

        const instagramUrl = `https://www.instagram.com/create/?url=${imageUrl}&caption=${caption}`;

        window.open(instagramUrl, "_blank");
        break;
      case "wa":
        const message = encodeURIComponent(
          `MASSGU FANCY CHOICE\nProduct Name: ${product.title}\nProduct Category: ${product.subcategory_name}\nProduct Brand: ${product.brand}\nProduct Color: ${product.color}\nProduct Price: ${product.current_price}\nProduct Link: ${currentURL}`
        );

        const whatsappUrl = `https://wa.me/?text=${message}`;
        window.open(whatsappUrl, "_blank");
        break;
      default:
        break;
    }
  };

  const handleSearch = (keyword) => {};
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };
  
  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>{product.title}</title>
        <meta property="og:title" content={product.title} />
        <meta property="og:description" content={product.pdesc} />
        <meta property="og:image" content={"http://api.massgu.com/images/" + product.image} />
        <meta property="og:site_name" content="Massgu Fancy Choice" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header handleSearch={handleSearch} cartItems={cartItems} />
      <Container>
        <ul className="w-full flex justify-start items-start py-2 border-b-2 space-x-2 bg-white px-2">
          <li>
            <a href="/">Home</a>
          </li>
          <li>/</li>
          <li>
            <a href="/home/search">Search Result</a>
          </li>
          <li>/</li>
          <li className="text-customC">
            <a
            href={'/home/search#searchcat'+String(category).replace(/\s/g, '')}
            >{category}</a>
          </li>
          <li>/</li>
          <li className="text-customC overflow-hidden text-ellipsis whitespace-nowrap">
            <a title={product.subcategory_name}
            href={'/home/search#searchsubcat'+String(product.subcategory_name).replace(/\s/g, '')}>
            {product.subcategory_name}
            </a>
          </li>
          <li>/</li>
          <li className="text-customC overflow-hidden text-ellipsis w-40 whitespace-nowrap">
            <a
            title={product.title}
            href={currentURL}>
            {product.title}
            </a>
          </li>
        </ul>
      </Container>
      <Container>
        <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 py-4 bg-white">
          <div className="p-2 bg-white col-span-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            <div className="px-8">
              <Slider {...settings}>
                {img.split(',:,').map((image,index)=>{
                  return(
                    <div>
                      <img
                      alt={`Product ${index}`}
                      key={index}
                      className={"mx-auto p-0 max-h-96"}
                      src={`http://api.massgu.com/images/${image.trim()}`}
                      />
                    </div>
                  )
                })}
              </Slider>
              <div className="flex justify-around items-center">
                {img.split(',:,').map((image,index)=>{
                  return(
                      <img
                      alt={`Product ${index}`}
                      key={index}
                      className={"mx-auto py-4 px-1 max-h-24"}
                      src={`http://api.massgu.com/images/${image.trim()}`}
                      />
                  )
                })}
                </div>
              <ul className="space-x-4 flex justify-center py-2">
              <li>
                <button
                  onMouseEnter={() => setIsHoveredfb(true)}
                  onMouseLeave={() => setIsHoveredfb(false)}
                  onClick={() => handleSocialShare("fb")}
                  className="text-blue-700"
                  title="share to facebook"
                >
                  {isHoveredfb ? (
                    "Share on Facebook"
                  ) : (
                    <span className="fa fa-facebook"></span>
                  )}
                </button>
              </li>
              <li>
                <button
                  onMouseEnter={() => setIsHoveredin(true)}
                  onMouseLeave={() => setIsHoveredin(false)}
                  onClick={() => handleSocialShare("in")}
                  className="text-pink-500"
                  title="share to instagram"
                >
                  {isHoveredin ? (
                    "Share on Instagram"
                  ) : (
                    <span className="fa fa-instagram"></span>
                  )}
                </button>
              </li>
              <li>
                <button
                  onMouseEnter={() => setIsHoveredwa(true)}
                  onMouseLeave={() => setIsHoveredwa(false)}
                  onClick={() => handleSocialShare("wa")}
                  className="text-green-500"
                  title="share to whatsapp"
                >
                  {isHoveredwa ? (
                    "Share on WhatsApp"
                  ) : (
                    <span className="fa fa-whatsapp"></span>
                  )}
                </button>
              </li>
            </ul>
            <p className="text-base text-center text-slate-500 my-2">
              Description
            </p>
            <div className="mystyle w-full">
              <div
              dangerouslySetInnerHTML={{ __html: product.pdesc !== "" ? 
              product.pdesc : "No Description Available." }}>
              </div>
            </div>
            </div>
            <div className="px-4">
              <h1 className="text-xl py-2">{product.title}</h1>
              <p className="text-xs text-slate-500">
                Brand: {" "}
                <span className="text-blue-600">
                  {product.brand !== "" ?
                  <a href={`/home/search#Brand${String(product.brand).replace(
                      /\s|-/g,
                      ""
                    )}`}>{product.brand}</a> : " No Brand"}
                </span>
              </p>
              <p className="text-xs text-slate-500">
                Type: {" "}
                <span className="text-blue-600">
                  {product.ptype !== "" ?
                  <a href={`/home/search#pType${String(product.ptype).replace(
                    /\s/g,
                    ""
                  )}`}>{product.ptype}</a>
                   : " No Type"}
                </span>
              </p>
              <hr className="my-4 text-slate-400" />
              <h1 className="text-4xl pt-2 text-customB">
                Rs. {parseInt(product.current_price)}
              </h1>
              <h3 className="text-base text-slate-500">
                <del>Rs. {parseInt(product.old_price)}</del>
                {parseInt(((parseInt(product.old_price) - parseInt(product.current_price)) / parseInt(product.old_price)) * 100) > 1 ? (
                        <span className="px-2 rounded-full text-rose-700">{parseInt(((parseInt(product.old_price) - parseInt(product.current_price)) / parseInt(product.old_price)) * 100)}% Off</span>
                ) : null}
              </h3>
              <hr className="my-4 text-slate-400" />
              <h3 className="text-base text-slate-500">
                Quantity:
                <button
                  className="ml-4 px-2 bg-slate-100 hover:bg-slate-200"
                  onClick={() =>
                    setQuantity((getQuantity) => Math.max(1, getQuantity - 1))
                  }
                >
                  -
                </button>
                <p className="text-black inline-block mx-4">{getQuantity}</p>
                <button
                  className="px-2 bg-slate-100 hover:bg-slate-200"
                  onClick={() =>
                    setQuantity((getQuantity) => Math.max(1, getQuantity + 1))
                  }
                >
                  +
                </button>
              </h3>
              <button
                className={
                  cartItems.findIndex((cartItem) => cartItem.id === product.id) === -1
                    ? "my-4 mr-2 px-4 py-2 bg-customC text-white text-center hover:bg-customB"
                    : "my-4 mr-2 px-4 py-2 bg-gray-600 text-white text-center hover:bg-gray-700"
                }
                disabled={
                  cartItems.findIndex((cartItem) => cartItem.id === product.id) === -1
                    ? false
                    : true}
                onClick={() => addToCart(product, Math.max(1, getQuantity))}
                title="add to cart"
              >
                {cartItems.findIndex((cartItem) =>
                 cartItem.id === product.id)===-1 ?
                 "Add to Cart":"Added to Cart"}
              </button>
              <button
                className="my-4 px-4 py-2 bg-green-500 text-white text-center hover:bg-green-600"
                onClick={sendWhatsAppMessage}
                title="send to WhatsApp"
              >
                Send To WhatsApp
              </button>
            </div>
            <div className="p-2 bg-gray-50 col-span-full md:col-span-1 space-y-4">
              <div className="flex justify-between items-center text-xs text-gray-500 font-semibold">
                <p>Delivery</p>
                <span className="fa fa-info-circle"></span>
              </div>
              <div
              className="flex items-start gap-2 bg-white p-1 text-sm">
                <img src={"/resources/location-icon.svg"} alt="Location Icon" className="m-1 w-[18.72px]"/>
                <p className="">Main Rawalpindi Road, Seni Gumbat Bazar,Nearby Pak State Oil 
                  (PSO) "Petrol Station".</p>
              </div>
              <div className="flex items-start gap-2 text-sm bg-white p-1">
                <img src={"/resources/delivery.svg"} alt="Location Icon" className="m-1 w-4 h-4" />
                <div className="flex justify-between items-center w-full">
                  <p>
                    <strong>Free Delivery </strong>
                    {new Date().toLocaleDateString('en-US', {day: 'numeric' , month: 'short'})} - 
                    {(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)).toLocaleDateString('en-US', {day: 'numeric', month: 'short' })}<br />
                    <span className="text-slate-400 text-xs">24 - 48 hour(s)</span>
                  </p>
                  <p>
                    <strong>Free</strong>
                  </p>
                </div>
              </div>
              <div className="flex items-start gap-2 text-sm bg-white p-1 mx-6 shadow">
                <p>
                  Enjoy free shipping promotion from massgu fancy choice.
                </p>
              </div>
              <div
              className="flex items-start gap-2 bg-white p-1 text-sm">
                <img src={"/resources/cash.svg"} alt="Location Icon" className="m-1 w-[18.72px]"/>
                <p className="">Cash on Delivery Available</p>
              </div>
              <div className="flex justify-between items-center text-xs text-gray-500 font-semibold">
                <p>Service</p>
                <span className="fa fa-info-circle"></span>
              </div>
              <div className="bg-white p-1 space-y-4">
                <div className="flex items-start gap-2 text-sm">
                  <img src={"/resources/cycle.svg"} alt="Location Icon" className="m-1 w-[18.72px]"/>
                  <p>
                    14 days free & easy replace <br />
                    <span className="text-slate-400 text-xs">Change of mind is not applicable</span>
                  </p>
                </div>
                <div className="flex items-start gap-2 text-sm">
                  <img src={"/resources/sec.svg"} alt="Location Icon" className="m-1 w-[18.72px]"/>
                  <p>
                    For warranty check brand's policy
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center text-xs text-gray-500 font-semibold bg-white p-1">
                <p>Sold by
                  <br />
                  <a href="/" className="text-sm font-semibold text-black">Massgu Fancy Choice</a>
                </p>
                <button
                className="text-sm text-blue-500 hover:text-blue-700"
                onClick={() => handleSocialShare("wa")}
                >
                  <span className=" fa fa-comments mx-1"></span>
                  CHAT
                </button>
              </div>
              <div className="flex justify-between items-start text-xs text-gray-500 font-semibold space-x-1">
                <div className="bg-white p-1 w-full">
                  <p className="h-12">Positive Seller Ratings</p>
                  <h1 className="py-2 text-2xl text-center text-black">100%</h1>
                </div>
                <div className="bg-white p-1 w-full">
                  <p className="h-12">Ship on Time</p>
                  <h1 className="py-2 text-2xl text-center text-black">100%</h1>
                </div>
                <div className="bg-white p-1 w-full">
                  <p className="h-12">Chat Response Rate</p>
                  <h1 className="py-2 text-2xl text-center text-black">100%</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
      <Container param="bg-[#2c0c24]">
        <section className="py-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 bg-[#2c0c24]">
          <div className="">
            <div className="text-customA text-xl font-bold text-center sm:text-left">
              <img src="/resources/logo2.png" alt="logo"/>
            </div>
            <p className="text-gray-100 text-sm py-2 text-center sm:text-left">
              MASSGU Fancy CHOICE is mainly dealing as wholesaler in mobile &
              accessories / CCTV Cameras & Services and other electronics and
              General Items in Seni Gumbat, Kohat. Massgu Fancy choice is the
              best choice for individuals, families & resellers for it's quality
              products and COD based deals.
            </p>
          </div>
          <div className="">
            <h3 className="text-base text-gray-100 font-semibold text-center">
              Follow us on
            </h3>
            <ul className="py-2 text-sm text-gray-100 text-center space-y-1">
              <li>
                <a href="https://www.youtube.com/@MassguFancyChoice">
                  <span className="fa fa-youtube"></span> Youtube
                </a>
              </li>
              <li>
                <a href="https://web.facebook.com/massgufancychoice/">
                  <span className="fa fa-facebook"></span> Facebook
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/massgufancychoice">
                  <span className="fa fa-instagram"></span> Instagram
                </a>
              </li>
              <li>
                <a href="https://wwww.tiktok.com/@massgufancychoice?lang=en">
                  <span className="fa fa-music"></span> Tiktok
                </a>
              </li>
              <li>
                <a href="https://www.pinterest.com/massgufancychoice/">
                  <span className="fa fa-pinterest"></span> Pinterest
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <h3 className="text-base text-gray-100 font-semibold text-center">
              Contact
            </h3>
            <ul className="py-2 text-sm text-gray-100 text-center space-y-1">
              {/* <li>
                  <p>Phone: 0000000000</p>
                </li>
                <li>
                  <p>email: test@mail.com</p>
                </li> */}
              <li>
                <a href="https://wa.me/+923324903770">
                  <span className="fa fa-whatsapp"></span> +923324903770
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <h3 className="text-base text-gray-100 font-semibold text-center">
              Address
            </h3>
            <ul className="py-2 text-sm text-gray-100 text-center space-y-1">
              <li>
                <p>
                  Address: Main Rawalpindi Road, Seni Gumbat Bazar, Nearby PSO
                  "Petrol Station", Sign Board: Massgu Fancy Choice.
                </p>
              </li>
            </ul>
          </div>
        </section>
      </Container>
    </div>
  );
}

export default Product;
