import React, { useState, useEffect } from "react";
import axios from "axios";

function AddSubcat() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        "http://api.massgu.com/api/auth/categories"
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const handleAddSubcategory = async () => {
    try {
      const response = await axios.post(
        "http://api.massgu.com/api/auth/subcategories",
        {
          category_id: selectedCategory,
          name: subCategoryName,
        }
      );

      if (response.status === 201) {
        setAlertMessage("Subcategory created successfully");
        window.location.reload();
        setIsError(false);
        setSelectedCategory("");
        setSubCategoryName("");
      }
    } catch (error) {
      console.error("Error creating subcategory:", error);
      setAlertMessage("Error creating subcategory: " + error.message);
      setIsError(true);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAlertMessage("");
    }, 3000);

    return () => clearTimeout(timeout);
  }, [alertMessage]);

  return (
    <div className="col-span-full my-2 rounded-md border shadow p-2">
      <h1 className="text-customA font-bold">Add Sub-Category</h1>
      {alertMessage && (
        <div
          className={`alert ${isError ? "alert-danger" : "alert-success"}`}
          role="alert"
        >
          {alertMessage}
        </div>
      )}
      <select
        className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block text-gray-600"
        value={selectedCategory}
        onChange={(e) => {
          setSelectedCategory(e.target.value);
        }}
      >
        <option value="" disabled>
          Select Category
        </option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>

      <input
        type="text"
        placeholder="Sub-Category Name"
        className="border py-1 px-2 rounded-md outline-customA bg-white my-2 inline-block"
        value={subCategoryName}
        onChange={(e) => setSubCategoryName(e.target.value)}
      />
      <button
        className="py-1 px-2 bg-customA text-white rounded-md"
        onClick={handleAddSubcategory}
      >
        +
      </button>
    </div>
  );
}

export default AddSubcat;
