import React, { useState, useEffect } from "react";
import axios from "axios";

function ListCat() {
  const [categories, setCategories] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editCategory, setEditCategory] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        "http://api.massgu.com/api/auth/categories"
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      const response = await axios.delete(
        `http://api.massgu.com/api/auth/categories/${categoryId}`
      );

      if (response.status === 204) {
        console.log("Category deleted successfully");
        
        fetchCategories();
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleEditCategory = (category) => {
    setEditCategory(category);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditCategory(null);
    setEditModalOpen(false);
  };

  const handleEditCategorySubmit = async (event) => {
    event.preventDefault();
    try {
      console.log(editCategory.name);
      const response = await axios.put(
        `http://api.massgu.com/api/auth/categories/${editCategory.id}`,
        {
          name: editCategory.name,
        }
      );

      if (response.status === 200) {
        console.log("Category updated successfully");
        fetchCategories();
        handleCloseEditModal();
      }
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };
  return (
    <div>
      <table className="table-auto w-full border-collapse border border-customA">
        <thead>
          <tr>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Name
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Delete
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Edit
            </th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.id} className="border-customA hover:bg-customD">
              <td className="border px-4 py-2">{category.name}</td>
              <td
                className="border px-4 py-2 text-red-500 hover:text-blue-800 cursor-pointer"
                onClick={() => handleDeleteCategory(category.id)}
              >
                Delete
              </td>
              <td
                className="border px-4 py-2 text-green-500 hover:text-blue-800 cursor-pointer"
                onClick={() => handleEditCategory(category)}
              >
                Edit
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Edit Modal */}
      {editModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-2 rounded-lg shadow-lg w-full md:w-1/2 space-y-4">
            <div className="bg-customC text-white text-2xl font-bold text-center py-2 shadow">
              Edit Category
            </div>
            <form onSubmit={handleEditCategorySubmit}>
              <input
                type="text"
                placeholder="Category Name"
                className="border py-1 px-2 rounded-md outline-customA bg-white my-2 inline-block w-full"
                value={editCategory.name}
                onChange={(e) =>
                  setEditCategory({ ...editCategory, name: e.target.value })
                }
              />
              <div className="flex justify-around items-center">
                <button
                  className="px-2 py-1 bg-customC text-white rounded-md hover:bg-customA"
                  title="Cancel"
                  onClick={handleCloseEditModal}
                >
                  Cancel
                </button>
                <button
                  className="px-2 py-1 bg-customC text-white rounded-md hover:bg-customA"
                  title="Save"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListCat;
