import React, { useState, useEffect } from "react";
import Container from "./components/Container";
import axios from "axios";
import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";

function Login() {
  const { isLoggedIn, login } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, [isLoggedIn, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "http://api.massgu.com/api/auth/login",
        { email, password }
      );

      if (response.status === 200) {
        login();
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      } else {
        throw new Error("Login failed: " + response.statusText);
      }
    } catch (error) {
      console.error("Error logging in:", error.message);
      setShowAlert(true); // Display alert for unsuccessful login
      setTimeout(() => {
        setShowAlert(false); // Hide alert after 3 seconds
      }, 3000);
    }
  };

  return (
    <Container>
      <div className="w-full md:w-1/2 bg-customC rounded-md p-4 mx-auto my-10">
        <h1 className="text-white font-bold text-2xl text-center my-4">
          Admin Login
        </h1>
        {showAlert && (
          <div className="alert alert-danger text-center text-white " role="alert">
            Login failed. Please check your credentials.
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <input
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="py-1 px-2 rounded-md outline-customA bg-white block mx-auto my-2"
          />
          <input
            type={"password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="py-1 px-2 rounded-md outline-customA bg-white block mx-auto my-2"
          />
          <button
            type="submit"
            className="py-1 px-2 bg-customA text-white rounded-md block mx-auto"
          >
            Login
          </button>
        </form>
      </div>
    </Container>
  );
}

export default Login;
