import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BannerSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
  };

  return (
    <div className="relative container mx-auto">
      <Slider {...settings}>
        <div>
          <img
            src="/resources/banner1.jpg"
            alt="Slide 1"
            className="w-full h-auto mx-auto rounded-xl"
          />
        </div>
        <div>
          <img
            src="/resources/banner2.jpg"
            alt="Slide 2"
            className="w-full h-auto mx-auto rounded-xl"
          />
        </div>
        <div>
          <img
            src="/resources/banner3.jpg"
            alt="Slide 3"
            className="w-full h-auto mx-auto rounded-xl"
          />
        </div>
        <div>
          <img
            src="/resources/banner4.jpg"
            alt="Slide 4"
            className="w-full h-auto mx-auto rounded-xl"
          />
        </div>
        <div>
          <img
            src="/resources/banner5.jpg"
            alt="Slide 5"
            className="w-full h-auto mx-auto rounded-xl"
          />
        </div>
        <div>
          <img
            src="/resources/banner6.jpg"
            alt="Slide 6"
            className="w-full h-auto mx-auto rounded-xl"
          />
        </div>
        <div>
          <img
            src="/resources/banner7.jpg"
            alt="Slide 7"
            className="w-full h-auto mx-auto rounded-xl"
          />
        </div>
        <div>
          <img
            src="/resources/banner8.jpg"
            alt="Slide 8"
            className="w-full h-auto mx-auto rounded-xl"
          />
        </div>
        <div>
          <img
            src="/resources/banner9.jpg"
            alt="Slide 9"
            className="w-full h-auto mx-auto rounded-xl"
          />
        </div>
        <div>
          <img
            src="/resources/banner10.jpg"
            alt="Slide 10"
            className="w-full h-auto mx-auto rounded-xl"
          />
        </div>
      </Slider>
    </div>
  );
};

export default BannerSlider;