import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Container from "./components/Container";
// import BannerSlider from "./components/BannerSlider";
import axios from "axios";
// import FeatureSlider from "./components/FeatureSlider";

function Home({ cartItems, addToCart }) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [filteredSubcat, setFilteredSubcat] = useState([]);
  const [filteredBrand, setFilteredBrand] = useState([]);
  const [filteredTypes, setFilteredType] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [Selectedbadge, setSelectedBadge] = useState("");
  const [dropDownClass, setdropDownClass] = useState("hidden");
  const [mySort, setMySort] = useState("3");

  useEffect(() => {
    fetchCategories();
  }, []);
  useEffect(() => {
    if (selectedCategory) {
      if (selectedCategory === "All") {
        setFilteredSubcat(subCategories);
      } else {
        const filter = subCategories.filter(
          (subcat) => subcat.category_id === selectedCategory
        );
        setFilteredSubcat(filter);
      }
    }
  }, [selectedCategory, subCategories]);

  const fetchCategories = async () => {
    try {
      const categoryResponse = await axios.get(
        "http://api.massgu.com/api/auth/categories"
      );
      setCategories(categoryResponse.data);

      const subCategoryResponse = await axios.get(
        "http://api.massgu.com/api/auth/subcategories"
      );
      setSubCategories(subCategoryResponse.data);
      setFilteredSubcat(subCategoryResponse.data);

      const productResponse = await axios.get(
        "http://api.massgu.com/api/auth/products"
      );
      setProducts(productResponse.data);
      setFilteredProducts(productResponse.data);
      setFilteredBrand(productResponse.data.map((product)=> product.brand))
      setFilteredType(productResponse.data.map((product)=> product.ptype))
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleSubCategoryClick = (subCategoryName) => {
    if (subCategoryName === "All") {
      setSelectedSubCategory("ALL");
      setFilteredProducts(products);
    } else {
      const filteredProducts = products.filter(
        (product) => product.subcategory_name === subCategoryName
      );
      setSelectedSubCategory(subCategoryName);
      setFilteredProducts(filteredProducts);
    }
    setSearchKeyword(subCategoryName);
    const filteredBrands = products
      .filter((product) => product.subcategory_name === subCategoryName)
      .map((product) => product.brand);
    setFilteredBrand(filteredBrands);
    const filteredType = products
      .filter((product) => product.subcategory_name === subCategoryName)
      .map((product) => product.ptype);
    setFilteredType(filteredType);
  };

  const handleTypeFilter = (selectedType) => {
    setSearchKeyword(selectedType);
    if (selectedType === "All") {
      setFilteredProducts(products);
      setSelectedType("All");
    } else {
      const filteredProducts = products.filter(
        (product) => product.ptype === selectedType
      );
      setSelectedType(selectedType);
      setFilteredProducts(filteredProducts);
    }
  };

  const handleBrandFilter = (selectedBrand) => {
    setSearchKeyword(selectedBrand);
    if (selectedBrand === "All") {
      setFilteredProducts(products);
      setSelectedBrand("All");
    } else {
      const filteredProducts = products.filter(
        (product) => product.brand === selectedBrand
      );
      setSelectedBrand(selectedBrand);
      setFilteredProducts(filteredProducts);
    }
  };

  const handleSearch = (keyword) => {
    const filteredProducts = products.filter((product) =>
      product.title.toLowerCase().includes(keyword.toLowerCase())
    );
    setFilteredProducts(filteredProducts);
    setSearchKeyword(keyword);
  };

  const handleSearchTwo = () => {
    let filtered = products.filter((product) => {
      if (minPrice && parseInt(product.current_price) < parseInt(minPrice)) {
        return false;
      }
      if (maxPrice && parseInt(product.current_price) > parseInt(maxPrice)) {
        return false;
      }
      return true;
    });
    setFilteredProducts(filtered);
  };

  const handleFilterDropDown = () => {
    if (dropDownClass === "hidden") {
      setdropDownClass("block");
    } else {
      setdropDownClass("hidden");
    }
  };

  const handleMySort = (SortType) => {
    setMySort(SortType);

    if (SortType === "0") {
      setFilteredProducts(
        [...filteredProducts].sort((a, b) => a.current_price - b.current_price)
      );
    } else if (SortType === "1") {
      setFilteredProducts(
        [...filteredProducts].sort((a, b) => b.current_price - a.current_price)
      );
    } else if (SortType === "3") {
      setFilteredProducts(products);
    }
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    if (categoryId === "All") {
      setFilteredSubcat(subCategories);
    } else {
      const filter = subCategories.filter(
        (subcat) => subcat.category_id === selectedCategory
      );
      setFilteredSubcat(filter);
    }
    fetchCategories();
  };

  const handleBadgeClick = (Selectedbadge) => {
    setSearchKeyword(Selectedbadge);
    if (Selectedbadge === null) {
      const filter = products.filter(
        (pro) => pro.badge === Selectedbadge);
      setFilteredProducts(filter);
    } else {
      const filter = products.filter(
        (pro) => pro.badge === Selectedbadge);
      setFilteredProducts(filter);
    }
    setSelectedBadge(Selectedbadge);
  };

  const brandCount = {};
  filteredBrand.forEach(product => {
    if (brandCount[product]) {
        brandCount[product]++;
    } else {
        brandCount[product] = 1;
    }
  });
  const typeCount = {};
  filteredTypes.forEach(product => {
    if (typeCount[product]) {
        typeCount[product]++;
    } else {
        typeCount[product] = 1;
    }
  });
  const brandCountArray = Object.entries(brandCount);
  brandCountArray.sort((a, b) => b[1] - a[1]);
  const typeCountArray = Object.entries(typeCount);
  typeCountArray.sort((a, b) => b[1] - a[1]);
  const uniqueBadge = Array.from(new Set(products.map((product) => product.badge)));
  const uniqueBadgeSymbol = Array.from(new Set(products.map((product) => product.badge_symbol)));

  const getCatfromPro = (subCatId, Allsubcat = subCategories, Allcat = categories) => {
    const getsubCat = Allsubcat.find((subcat) => subcat.id === subCatId);
    if (getsubCat) {
      const getCat = Allcat.find((cat) => cat.id === getsubCat.category_id);
      if (getCat) {
        return getCat.name;
      } else {
        return "Category Not Found";
      }
    } else {
      return "Subcategory Not Found";
    }
  };

  useEffect(() => {
    const handleHashChangeWithDelay = () => {
      setTimeout(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          const targetElement = document.getElementById(hash);
          if (targetElement) {
            targetElement.click();
          } else if (hash.startsWith("SearchQ=")) {
            // Extract the search query value from the hash
            const searchQValue = hash.split("=")[1];
            // Focus on the input field and set its value
            const searchBarInput = document.getElementById("mysearchbar");
            if (searchBarInput) {
              searchBarInput.focus();
              searchBarInput.value = searchQValue;
              handleSearch(searchQValue);
            }
          }
        }
      }, 5000);
    };
  
    window.addEventListener('hashchange', handleHashChangeWithDelay);
  
    handleHashChangeWithDelay();
  
    return () => {
      window.removeEventListener('hashchange', handleHashChangeWithDelay);
    };
  }, []);
  
  
  const [showAllCategories, setShowAllCategories] = useState(false);

  const toggleCategories = () => {
    setShowAllCategories(!showAllCategories);
  };
  
  const [showAllSubcategories, setShowAllSubcategories] = useState(true);

  const toggleSubcategories = () => {
    setShowAllSubcategories(!showAllSubcategories);
  };

  const [showAllTypes, setShowAllTypes] = useState(true);

  const toggleTypes = () => {
    setShowAllTypes(!showAllTypes);
  };

  const [showAllBrands, setShowAllBrands] = useState(true);

  const toggleBrands = () => {
    setShowAllBrands(!showAllBrands);
  };
  
  return (
    <div className="bg-gray-100">
      <Header handleSearch={handleSearch} cartItems={cartItems} />
      <Container>
        <ul className="w-full flex justify-start items-center py-2 border-b-2 space-x-2 bg-white px-2">
          <li>
            <a href="/">Home</a>
          </li>
          <li>/</li>
          <li className="text-customC">
            <a href="/home/search">Search Result</a>
          </li>
        </ul>
      </Container>
      <Container>
        <section className="grid grid-cols-12 gap-2 my-2">
          <div className="col-span-full md:col-span-2 rounded-md bg-white px-2 md:hidden">
            <button onClick={() => handleFilterDropDown()}>
              <h1 className="text-xl font-semibold py-2">
                Filters <span className="fa fa-caret-down"></span>
              </h1>
            </button>
          </div>
          <div
            className={
              "col-span-full md:col-span-2 rounded-md bg-white px-2 md:block " +
              dropDownClass
            }
          >
            <h1 className="text-xl font-semibold py-2 hidden md:block">
              Filters
            </h1>
            <p className="text-sm md:text-base text-gray-700 border-b-2 py-2 font-semibold">
              badges
            </p>
            <ul className="py-2 flex flex-wrap text-xs md:text-sm text-gray-700 gap-2">
            {uniqueBadge.map((badge, index) => (
              <li key={index}>
                {badge === null ? (
                  <button
                    className={
                    "text-slate-700 py-1 px-2 flex items-center space-x-1 border rounded-md hover:shadow" + 
                    (Selectedbadge=== badge ? " bg-slate-200" : " bg-white")}
                    onClick={() => { handleBadgeClick(badge) }}
                    onDoubleClick={()=>{handleBadgeClick("");handleSubCategoryClick("All")}}
                  >
                    <span className="text-rose-600 mr-1 fa fa-star"></span>
                    other
                  </button>
                ) : (
                  <button
                  className={
                    "text-slate-700 py-1 px-2 flex items-center space-x-1 border rounded-md hover:shadow" + 
                    (Selectedbadge=== badge ? " bg-slate-200" : " bg-white")}
                    onDoubleClick={()=>{handleBadgeClick("");handleSubCategoryClick("All")}}
                    onClick={() => { handleBadgeClick(badge) }}
                  >
                    <span className={`text-rose-600 mr-1 ${uniqueBadgeSymbol[index]}`}></span>
                    {badge}
                  </button>
                )}
              </li>
            ))}

            </ul>
            <p className="text-sm md:text-base text-gray-700 border-b-2 py-2 font-semibold">
              Categories
            </p>
            <ul className="py-2 space-y-1 text-xs md:text-sm text-gray-700">
              {categories.slice(0, showAllCategories ? categories.length : 5).map((category) => (
                <li
                  key={category.id}
                  className={
                    "overflow-hidden text-ellipsis whitespace-nowrap" +
                    (selectedCategory === category.id ? " bg-slate-200" : " bg-transparent")
                  }
                  title={category.name}
                >
                  <button
                    onClick={() => handleCategoryClick(category.id)}
                    id={"searchcat" + String(category.name).replace(/\s/g, "")}
                    onDoubleClick={() => handleCategoryClick("All")}
                  >
                    {category.name}
                  </button>
                </li>
              ))}
              <li className={showAllCategories ? "" : "hidden"}>
                <button onClick={toggleCategories}
                className="my-1 text-customC"
                >- View less</button>
              </li>
              {!showAllCategories && (
                <li>
                  <button onClick={toggleCategories}
                  className="my-1 text-customC"
                  >+ View more</button>
                </li>
              )}
            </ul>

            <p className="text-sm md:text-base text-gray-700 border-b-2 py-2 font-semibold">
              Sub Categories
            </p>
            <ul className="py-2 space-y-1 text-xs md:text-sm text-gray-700">
              <li key="all" className={showAllSubcategories ? "hidden" : ""}>
                <button onClick={() => handleSubCategoryClick("All")}>All</button>
              </li>
              {filteredSubcat.slice(0, showAllSubcategories ? filteredSubcat.length : 5).map((subcategory) => (
                <li
                  key={subcategory.id}
                  className={
                    "overflow-hidden text-ellipsis whitespace-nowrap" +
                    (selectedSubCategory === subcategory.name ? " bg-slate-200" : " bg-transparent")
                  }
                  title={subcategory.name}
                >
                  <button
                    id={"searchsubcat" + String(subcategory.name).replace(/\s/g, "")}
                    onClick={() => handleSubCategoryClick(subcategory.name)}
                    onDoubleClick={() => handleSubCategoryClick("All")}
                  >
                    {subcategory.name}
                  </button>
                </li>
              ))}
              <li className={showAllSubcategories ? "" : "hidden"}>
                <button onClick={toggleSubcategories}
                className="my-1 text-customC"
                >- View less</button>
              </li>
              {!showAllSubcategories && (
                <li>
                  <button onClick={toggleSubcategories}
                  className="my-1 text-customC"
                  >+ View more</button>
                </li>
              )}
            </ul>

            <p className="text-sm md:text-base text-gray-700 border-b-2 py-2 font-semibold">
              Brands
            </p>
            <ul className="py-2 space-y-1 text-xs md:text-sm text-gray-700">
              <li key="all" className={showAllBrands ? "hidden" : ""}>
                <input
                  type="radio"
                  name="brand"
                  id="allBrands"
                  value="All"
                  onChange={() => handleBrandFilter("All")}
                />
                <label htmlFor="brand" className="px-2">
                  All
                </label>
              </li>
              {brandCountArray.slice(0, showAllBrands ? brandCountArray.length : 5).map((brand) => (
                <li
                  key={brand[0]}
                  className={
                    (selectedBrand === brand[0] ? " bg-slate-200" : " bg-transparent") +
                    (showAllBrands ? "" : "")
                  }
                  onDoubleClick={() => handleBrandFilter("All")}
                >
                  <input
                    type="radio"
                    name="brand"
                    id={"Brand" + String(brand[0]).replace(/\s|-/g, "")}
                    value={brand[0]}
                    onChange={() => handleBrandFilter(brand[0])}
                  />
                  <label htmlFor={brand[0]} className="px-2">
                    {brand[0]} <small className="text-customC">({brand[1]})</small>
                  </label>
                </li>
              ))}
              <li className={showAllBrands ? "" : "hidden"}>
                <button onClick={toggleBrands}
                className="my-1 text-customC"
                >- View less</button>
              </li>
              {!showAllBrands && (
                <li>
                  <button onClick={toggleBrands}
                  className="my-1 text-customC"
                  >+ View more</button>
                </li>
              )}
            </ul>
            <p className="text-sm md:text-base text-gray-700 border-b-2 py-2 font-semibold">Products Type</p>
            <ul className="py-2 space-y-1 text-xs md:text-sm text-gray-700">
              <li key="all" className={showAllTypes ? "hidden" : ""}>
                <input
                  id="allTypes"
                  type="radio"
                  name="ptype"
                  value="All"
                  onChange={() => handleTypeFilter("All")}
                />
                <label htmlFor="ptype" className="px-2">
                  All
                </label>
              </li>
              {typeCountArray.slice(0, showAllTypes ? typeCountArray.length : 5).map((type) => (
                <li
                  key={type[0]}
                  className={
                    "flex items-center space-x-1" +
                    (selectedType === type[0] ? " bg-slate-200" : " bg-transparent") +
                    (showAllTypes ? "" : "")
                  }
                  onDoubleClick={() => handleTypeFilter("All")}
                >
                  <input
                    type="radio"
                    name="ptype"
                    id={"pType" + String(type[0]).replace(/\s/g, "")}
                    value={type[0]}
                    onChange={() => handleTypeFilter(type[0])}
                  />
                  <label htmlFor={"pType" + String(type[0]).replace(/\s/g, "")} className="px-2">
                    {type[0]} <small className="text-customC">({type[1]})</small>
                  </label>
                </li>
              ))}
              <li className={showAllTypes ? "" : "hidden"}>
                <button onClick={toggleTypes}
                className="my-1 text-customC"
                >- View less</button>
              </li>
              {!showAllTypes && (
                <li>
                  <button onClick={toggleTypes}
                  className="my-1 text-customC"
                  >+ View more</button>
                </li>
              )}
            </ul> 
            <p className="text-sm md:text-base text-gray-700 border-b-2 py-2 font-semibold">
              Price
            </p>
            <div className="flex-column md:flex items-center space-x-0 md:space-x-2 py-4 space-y-1 md:space-y-0">
              <input
                type="text"
                placeholder="MIN"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
                className="py-1 px-2 text-xs md:text-sm rounded-md border outline-customC w-12"
              />
              <input
                type="text"
                placeholder="MAX"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
                className="py-1 px-2 text-xs md:text-sm rounded-md border outline-customC w-12"
              />
              <button
                className="py-1 px-2 bg-customC text-white rounded-md text-xs md:text-sm"
                onClick={handleSearchTwo}
              >
                APPLY
              </button>
            </div>
          </div>
          <div className="col-span-full md:col-span-10 bg-white rounded-md">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2">
              {/* <div className="w-full col-span-full mb-10">
                <BannerSlider/>
              </div> */}
              {/* <div className="w-full col-span-full mb-10 border rounded-md">
                <h1 className="text-center uppercase text-xl font-bold text-black py-1">Featuring</h1>
                <FeatureSlider addToCart={addToCart}
                  cartItems={cartItems} 
                  products={products}
                  categories={categories}
                  subCategories={subCategories}
                />

              </div> */}
              
              <div className="col-span-full flex items-center justify-between py-4 border rounded-md px-2">
                <p className="text-sm text-gray-700">
                  {filteredProducts.length} items found for{" "}
                  <strong className="text-customC">'{searchKeyword}'</strong>
                </p>

                <div>
                  <div>
                    <label className="text-sm text-gray-700">Sort by</label>
                    <select
                      className="bg-white py-1 px-2 rounded-full border text-gray-700"
                      value={mySort}
                      onChange={(e) => handleMySort(e.target.value)}
                    >
                      <option value={0}>Low to High</option>
                      <option value={1}>High to Low</option>
                      <option value={3} disabled selected>
                        Latest
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              {filteredProducts.length <= 0 ? (
                <h1 className="col-span-full text-center py-10 text-red-500">
                  No item Found!
                </h1>
              ) : null}
              {filteredProducts.map((product) => (
                <div
                  key={product.id}
                  className="rounded-md bg-white p-1 productItem shadow hover:shadow-2xl"
                >
                  <div>
                    <div className="overflow-hidden relative">
                      <a href={`/${getCatfromPro(product.subcategory_id)}/${product.subcategory_name}/${product.title}/${product.id}`}>
                        {product.image.split(',:,').length > 1 && (
                          <div className="w-18 h-8 py-2 px-1 bg-black/50 text-white text-xs absolute rounded-tl-md rounded-br-md">
                            {product.image.split(',:,').length - 1} More...
                          </div>
                        )}
                        {product.image.split(',:,').map((image, index) => {
                          if (index === 0) {
                            return (
                            <img
                            key={index}
                            className=" w-auto mx-auto p-0 h-46 rounded-md text-white text-center"
                            src={`http://api.massgu.com/images/${image.trim()}`}
                            alt={`Product ${index + 1}`}/>
                            );
                          }
                          return null;
                          })}
                      </a>
                      <button
                        className={
                          cartItems.findIndex((cartItem) => cartItem.id === product.id) === -1
                            ? "absolute bottom-0 right-0 px-2 py-1 hover:px-3 hover:py-2 text-sm  bg-customC text-white rounded-full hover:bg-customA"
                            : "absolute bottom-0 right-0 px-2 py-1 hover:px-3 hover:py-2 text-sm rounded-full bg-gray-600 text-white text-center hover:bg-gray-700"
                        }
                        disabled={
                          cartItems.findIndex((cartItem) => cartItem.id === product.id) === -1
                            ? false
                            : true
                        }
                        onClick={() => {
                          addToCart(product);
                        }}
                        title="add to cart"
                      >
                        <span className="fa fa-shopping-cart"></span>
                      </button>
                    </div>
                    <div className="overflow-hidden">
                      <div className="w-full h-16 overflow-hidden">
                        <a
                          href={`/${getCatfromPro(product.subcategory_id)}/${product.subcategory_name}/${product.title}/${product.id}`}
                          className="text-sm text-gray-700"
                          id="title"
                          title={product.title}
                        >
                          {product.title}
                        </a>
                      </div>
                      <div className="h-8 mt-1 flex space-x-1 flex-wrap justify-center items-center">
                        <div className="py-1 border border-cyan-500 text-center">
                          <p className="text-cyan-500 text-xs">Free Delivery</p>
                        </div>
                        {parseInt(((parseInt(product.old_price) - parseInt(product.current_price)) / parseInt(product.old_price)) * 100) > 1 ? (
                            <div className="p-1 border border-rose-500 text-center">
                            <p className="text-rose-500 text-xs">{parseInt(((parseInt(product.old_price) - parseInt(product.current_price)) / parseInt(product.old_price)) * 100)}% Off</p>
                            </div>
                        ) : null}
                      </div>
                      <div className="flex items-center space-x-1 py-1 h-16 overflow-hidden">
                        <h3 className="text-customC">
                          RS. {parseInt(product.current_price)}
                        </h3>
                        <del className="text-gray-400 text-xs">
                          RS. {parseInt(product.old_price)}
                        </del>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="py-2 flex items-center justify-between px-4">
              <p className="text-sm text-gray-700">
                Did you find what you were looking for?
              </p>
              {filteredProducts.length >= 50 ? (
                <div className="space-x-2">
                  <button className="bg-gray-100 text-sm text-gray-700 py-1 px-2 rounded-md">
                    prev
                  </button>
                  <button className="bg-customC text-sm text-white py-1 px-2 rounded-md">
                    1
                  </button>
                  <button className="bg-gray-100 text-sm text-gray-700 py-1 px-2 rounded-md">
                    2
                  </button>
                  <button className="bg-gray-100 text-sm text-gray-700 py-1 px-2 rounded-md">
                    ...
                  </button>
                  <button className="bg-gray-100 text-sm text-gray-700 py-1 px-2 rounded-md">
                    9
                  </button>
                  <button className="bg-gray-100 text-sm text-gray-700 py-1 px-2 rounded-md">
                    next
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </Container>
      <Container param="bg-[#2c0c24]">
        <section className="py-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 bg-[#2c0c24]">
          <div className="">
            <div className="text-customA text-xl font-bold text-center sm:text-left">
              <img src="/resources/logo2.png" alt="logo"/>
            </div>
            <p className="text-gray-100 text-sm py-2 text-center sm:text-left">
              MASSGU Fancy CHOICE is mainly dealing as wholesaler in mobile &
              accessories / CCTV Cameras & Services and other electronics and
              General Items in Seni Gumbat, Kohat. Massgu Fancy choice is the
              best choice for individuals, families & resellers for it's quality
              products and COD based deals.
            </p>
          </div>
          <div className="">
            <h3 className="text-base text-gray-100 font-semibold text-center">
              Follow us on
            </h3>
            <ul className="py-2 text-sm text-gray-100 text-center space-y-1">
              <li>
                <a href="https://www.youtube.com/@MassguFancyChoice">
                  <span className="fa fa-youtube"></span> Youtube
                </a>
              </li>
              <li>
                <a href="https://web.facebook.com/massgufancychoice/">
                  <span className="fa fa-facebook"></span> Facebook
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/massgufancychoice">
                  <span className="fa fa-instagram"></span> Instagram
                </a>
              </li>
              <li>
                <a href="https://wwww.tiktok.com/@massgufancychoice?lang=en">
                  <span className="fa fa-music"></span> Tiktok
                </a>
              </li>
              <li>
                <a href="https://www.pinterest.com/massgufancychoice/">
                  <span className="fa fa-pinterest"></span> Pinterest
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <h3 className="text-base text-gray-100 font-semibold text-center">
              Contact
            </h3>
            <ul className="py-2 text-sm text-gray-100 text-center space-y-1">
              <li>
                <a href="https://wa.me/+923324903770">
                  <span className="fa fa-whatsapp"></span> +923324903770
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <h3 className="text-base text-gray-100 font-semibold text-center">
              Address
            </h3>
            <ul className="py-2 text-sm text-gray-100 text-center space-y-1">
              <li>
                <p>
                  Address: Main Rawalpindi Road, Seni Gumbat Bazar, Nearby PSO
                  "Petrol Station", Sign Board: Massgu Fancy Choice.
                </p>
              </li>
            </ul>
          </div>
        </section>
      </Container>
    </div>
  );
}

export default Home;
