import React, { useState, useEffect} from "react";
import Header from "./components/Header";
import Container from "./components/Container";
import BannerSlider from "./components/BannerSlider";
import "./RealHome.css"
import axios from "axios";
import FeatureSlider from "./components/FeatureSlider";

function Home({ cartItems, addToCart }) {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [dropDownClass, setdropDownClass] = useState("hidden");
  useEffect(() => {
    fetchCategories();
  }, []);
  const fetchCategories = async () => {
    try {
      const categoryResponse = await axios.get(
        "http://api.massgu.com/api/auth/categories"
      );
      setCategories(categoryResponse.data);

      const subCategoryResponse = await axios.get(
        "http://api.massgu.com/api/auth/subcategories"
      );
      setSubCategories(subCategoryResponse.data);

      const productResponse = await axios.get(
        "http://api.massgu.com/api/auth/products"
      );
      setProducts(productResponse.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  // const handleSearch = (keyword) => {
  //   id="mysearchbar"
  // };
  const handleSearch = (keyword) => {
    //window.location.href = `/home/search?#SearchQ=${encodeURIComponent(keyword)}`;
  };
  const handleSearchBtn = (state) =>{
      window.location.href = `/home/search?#SearchQ=${encodeURIComponent(state)}`;
  }
  const handleFilterDropDown = () => {
    if (dropDownClass === "hidden") {
      setdropDownClass("block");
    } else {
      setdropDownClass("hidden");
    }
  };
  const getCatfromPro = (subCatId, Allsubcat = subCategories, Allcat = categories) => {
    const getsubCat = Allsubcat.find((subcat) => subcat.id === subCatId);
    if (getsubCat) {
      const getCat = Allcat.find((cat) => cat.id === getsubCat.category_id);
      if (getCat) {
        return getCat.name;
      } else {
        return "Category Not Found";
      }
    } else {
      return "Subcategory Not Found";
    }
  };
  // const renderedPtypes = {};
  const uniqueTypes = Array.from(new Set(products.map((product) => product.ptype)));
  uniqueTypes.sort((a, b) => a.localeCompare(b));
  const uniqueBrands = Array.from(new Set(products.map((product) => product.brand)));
  uniqueBrands.sort((a, b) => a.localeCompare(b));
  return (
    <div className="bg-gray-100 overflow-hidden">
      <Header handleSearch={handleSearch}  handleSearchBtn={handleSearchBtn} cartItems={cartItems} />
      <Container>
        <section className="grid grid-cols-12 gap-2 my-2">
          <div className="col-span-full lg:col-span-2 rounded-md bg-white px-2 lg:hidden">
            <button onClick={() => handleFilterDropDown()}>
              <h1 className="text-xl font-semibold py-2">
                Filters <span className="fa fa-caret-down"></span>
              </h1>
            </button>
          </div>
          <div
          className={"sidecontainer col-span-full lg:col-span-2 lg:block " + dropDownClass}
          >
          <nav className="w-full">
              <ul className="mcd-menu">
              {categories.map((category, index) => (
                <li key={index}>
                  <a href="/"
                  title={category.name}
                  className={"pointer-events-none"}>
                    <i className="fa fa-list-alt"></i>
                    <strong
                    className="overflow-hidden text-ellipsis whitespace-nowrap"
                    >{category.name}</strong>
                  </a>
                  <ul className="flex">
                    {subCategories
                      .filter((subcategory) => subcategory.category_id === category.id)
                      .map((subcat, subindex) => (
                        <li key={subindex}>
                          <a href={'home/search#searchsubcat'+String(subcat.name).replace(/\s/g, '')}
                          className="overflow-hidden text-ellipsis whitespace-nowrap">
                            <i className="fa fa-list"></i>
                            {subcat.name}
                          </a>
                          <ul>
                          {uniqueTypes.map((Type, index) => {
                            const matchingProduct = products.find(
                              (pro) => pro.ptype === Type && pro.subcategory_id===subcat.id&& parseInt(pro.type_feature)===1);
                            if (matchingProduct) {
                              return (
                                <li key={index}>
                              <a href={`/${getCatfromPro(matchingProduct.subcategory_id)}/${matchingProduct.subcategory_name}/${matchingProduct.title}/${matchingProduct.id}`}
                              className="overflow-hidden text-ellipsis whitespace-nowrap">
                                <i className="">
                                {matchingProduct.image.split(',:,').map((image, index) => {
                                  if (index === 0) {
                                    return (
                                    <img
                                    key={index}
                                    className=" w-auto p-0 h-3 sm:h-6 md:h-8 lg:h-10 text-white"
                                    src={`http://api.massgu.com/images/${image.trim()}`}
                                    alt={`Product ${index + 1}`}/>
                                    );
                                  }
                                  return null;
                                  })}
                                </i>
                                {Type}
                              </a>
                            </li>
                              )}
                              else{
                                return(
                                  null
                                )
                              }
                            })}
                          </ul>
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
              </ul>
            </nav>
          </div>
          <div className="col-span-full lg:col-span-10 rounded-md">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2">
              <div className="w-full col-span-full mb-10">
                <BannerSlider/>
              </div>
            </div>
          </div>
        </section>
      </Container>
      <Container>
      <section className="grid grid-cols-12 gap-2">
        <div className="w-full col-span-full mb-10">
          <div className="w-full">
            <img src="/resources/SingleBanner.png" alt="banner" className="w-full"/>
          </div>
        </div>

        <div className="w-full col-span-full mb-10 bg-customD py-2 overflow-x-scroll rounded-md">
          <div className="flex justify-around items-center text-black w-max lg:w-full">
            <div className="flex items-center gap-2 text-xs md:text-sm">
              <img src={"/resources/badges/7.png"} alt="Location Icon" className="m-1 w-[20.72px]"/>
              <p className="w-full">Safe Payments</p>
            </div>
            <div className="flex items-center gap-2 text-xs md:text-sm">
              <img src={"/resources/badges/8.png"} alt="Location Icon" className="m-1 w-[20.72px]"/>
              <p className="w-full">Free Delivery</p>
            </div>
            <div className="flex items-center gap-2 text-xs md:text-sm">
              <img src={"/resources/badges/6.png"} alt="Location Icon" className="m-1 w-[20.72px]"/>
              <p className="w-full">Free & Easy Returns</p>
            </div>
            <div className="flex items-center gap-2 text-xs md:text-sm">
              <img src={"/resources/badges/12.png"} alt="Location Icon" className="m-1 w-[20.72px]"/>
              <p className="w-full">Best Price Guaranteed</p>
            </div>
            <div className="flex items-center gap-2 text-xs md:text-sm">
              <img src={"/resources/badges/10.png"} alt="Location Icon" className="m-1 w-[20.72px]"/>
              <p className="w-full">100% Authentic Products</p>
            </div>
            <div className="flex items-center gap-2 text-xs md:text-sm">
              <img src={"/resources/badges/15.png"} alt="Location Icon" className="m-1 w-[20.72px]"/>
              <p className="w-full">Massgu Verified</p>
            </div>
          </div>
        </div>
        <div className="w-full col-span-full py-2 rounded-md">
          <h1 className="text-lg text-slate-600">Featuring</h1>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 bg-white my-2 py-2">
            <div className="col-span-full">
              <FeatureSlider addToCart={addToCart}
                      cartItems={cartItems} 
                      products={products}
                      categories={categories}
                      subCategories={subCategories}
                    />
            </div>
          </div>
        </div>
        <div className="w-full col-span-full py-2 overflow-x-scroll rounded-md">
          <h1 className="text-lg text-slate-600">Types</h1>
          <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 bg-white my-2 py-2">
            {uniqueTypes.map((Type, index) => {
              const matchingProduct = products.find(
                (pro) => pro.ptype === Type && parseInt(pro.type_feature) === 1
              );

              if (matchingProduct) {
                return (
                  <a
                    href={`home/search#pType${String(matchingProduct.ptype).replace(
                      /\s/g,
                      ""
                    )}`}
                    key={index}
                  >
                    <div className="hover:shadow-md shadow">
                      <div className="m-0">
                        {matchingProduct.image.split(",:,").map((image, imgIndex) => (
                          <img
                            alt={`Product ${imgIndex}`}
                            key={imgIndex}
                            className="mx-auto p-1 h-[80px] max-h-[200px] rounded-lg"
                            src={`http://api.massgu.com/images/${image.trim()}`}
                          />
                        ))}
                      </div>
                      <div className="mx-[8px] text-center">
                        <h1 className="hover:text-blue-500 text-[14px] h-[46px] overflow-clip text-ellipsis whitespace-wrap">
                          {matchingProduct.ptype}
                        </h1>
                      </div>
                    </div>
                  </a>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
        <div className="w-full col-span-full py-2 overflow-x-scroll rounded-md">
          <h1 className="text-lg text-slate-600">Brands</h1>
          <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 bg-white my-2 py-2">
            {uniqueBrands.map((Brand, index) => {
              const matchingProduct = products.find(
                (pro) => pro.brand === Brand && parseInt(pro.brand_feature) === 1
              );

              if (matchingProduct) {
                return (
                  <a
                    href={`home/search#Brand${String(matchingProduct.brand).replace(
                      /\s|-/g,
                      ""
                    )}`}
                    key={index}
                  >
                    <div className="hover:shadow-md shadow">
                      <div className="m-0">
                        {matchingProduct.image.split(",:,").map((image, imgIndex) => (
                          <img
                            alt={`Product ${imgIndex}`}
                            key={imgIndex}
                            className="mx-auto p-1 h-[80px] max-h-[200px] rounded-lg"
                            src={`http://api.massgu.com/images/${image.trim()}`}
                          />
                        ))}
                      </div>
                      <div className="mx-[8px] text-center">
                        <h1 className="hover:text-blue-500 text-[14px] h-[46px] overflow-clip text-ellipsis whitespace-wrap">
                          {matchingProduct.brand}
                        </h1>
                      </div>
                    </div>
                  </a>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
        <div className="w-full col-span-full mb-10 py-2 overflow-x-scroll rounded-md">
          <h1 className="text-lg text-slate-600">Just For You</h1>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 bg-white my-2 py-2">
          {products
          .filter((product) => parseInt(product.pweight)===1)
          .map((product, index) => (
            <div
                  key={product.id}
                  className="rounded-md bg-white p-1 productItem shadow hover:shadow-2xl"
                >
                  <div>
                    <div className="overflow-hidden relative">
                      <a href={`/${getCatfromPro(product.subcategory_id)}/${product.subcategory_name}/${product.title}/${product.id}`}>
                        {product.image.split(',:,').length > 1 && (
                          <div className="w-18 h-8 py-2 px-1 bg-black/50 text-white text-xs absolute rounded-tl-md rounded-br-md">
                            {product.image.split(',:,').length - 1} More...
                          </div>
                        )}
                        {product.image.split(',:,').map((image, index) => {
                          if (index === 0) {
                            return (
                            <img
                            key={index}
                            className=" w-auto mx-auto p-0 h-46 rounded-md text-white text-center"
                            src={`http://api.massgu.com/images/${image.trim()}`}
                            alt={`Product ${index + 1}`}/>
                            );
                          }
                          return null;
                          })}
                      </a>
                      <button
                        className={
                          cartItems.findIndex((cartItem) => cartItem.id === product.id) === -1
                            ? "absolute bottom-0 right-0 px-2 py-1 hover:px-3 hover:py-2 text-sm  bg-customC text-white rounded-full hover:bg-customA"
                            : "absolute bottom-0 right-0 px-2 py-1 hover:px-3 hover:py-2 text-sm rounded-full bg-gray-600 text-white text-center hover:bg-gray-700"
                        }
                        disabled={
                          cartItems.findIndex((cartItem) => cartItem.id === product.id) === -1
                            ? false
                            : true
                        }
                        onClick={() => {
                          addToCart(product);
                        }}
                        title="add to cart"
                      >
                        <span className="fa fa-shopping-cart"></span>
                      </button>
                    </div>
                    <div className="overflow-hidden">
                      <div className="w-full h-16 overflow-hidden">
                        <a
                          href={`/${getCatfromPro(product.subcategory_id)}/${product.subcategory_name}/${product.title}/${product.id}`}
                          className="text-sm text-gray-700"
                          id="title"
                          title={product.title}
                        >
                          {product.title}
                        </a>
                      </div>
                      <div className="h-8 mt-1 flex space-x-1 flex-wrap justify-center items-center">
                        <div className="py-1 border border-cyan-500 text-center">
                          <p className="text-cyan-500 text-xs">Free Delivery</p>
                        </div>
                        {parseInt(((parseInt(product.old_price) - parseInt(product.current_price)) / parseInt(product.old_price)) * 100) > 1 ? (
                            <div className="p-1 border border-rose-500 text-center">
                            <p className="text-rose-500 text-xs">{parseInt(((parseInt(product.old_price) - parseInt(product.current_price)) / parseInt(product.old_price)) * 100)}% Off</p>
                            </div>
                        ) : null}
                      </div>
                      <div className="flex items-center space-x-1 py-1 h-16 overflow-hidden">
                        <h3 className="text-customC">
                          RS. {parseInt(product.current_price)}
                        </h3>
                        <del className="text-gray-400 text-xs">
                          RS. {parseInt(product.old_price)}
                        </del>
                      </div>
                    </div>
                  </div>
                </div>
          ))}
          </div>
        </div>
        
      </section>
      </Container>
      <Container param="bg-[#2c0c24]">
        <section className="py-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 bg-[#2c0c24]">
          <div className="">
            <div className="text-customA text-xl font-bold text-center sm:text-left">
              <img src="/resources/logo2.png" alt="logo"/>
            </div>
            <p className="text-gray-100 text-sm py-2 text-center sm:text-left">
              MASSGU Fancy CHOICE is mainly dealing as wholesaler in mobile &
              accessories / CCTV Cameras & Services and other electronics and
              General Items in Seni Gumbat, Kohat. Massgu Fancy choice is the
              best choice for individuals, families & resellers for it's quality
              products and COD based deals.
            </p>
          </div>
          <div className="">
            <h3 className="text-base text-gray-100 font-semibold text-center">
              Follow us on
            </h3>
            <ul className="py-2 text-sm text-gray-100 text-center space-y-1">
              <li>
                <a href="https://www.youtube.com/@MassguFancyChoice">
                  <span className="fa fa-youtube"></span> Youtube
                </a>
              </li>
              <li>
                <a href="https://web.facebook.com/massgufancychoice/">
                  <span className="fa fa-facebook"></span> Facebook
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/massgufancychoice">
                  <span className="fa fa-instagram"></span> Instagram
                </a>
              </li>
              <li>
                <a href="https://wwww.tiktok.com/@massgufancychoice?lang=en">
                  <span className="fa fa-music"></span> Tiktok
                </a>
              </li>
              <li>
                <a href="https://www.pinterest.com/massgufancychoice/">
                  <span className="fa fa-pinterest"></span> Pinterest
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <h3 className="text-base text-gray-100 font-semibold text-center">
              Contact
            </h3>
            <ul className="py-2 text-sm text-gray-100 text-center space-y-1">
              <li>
                <a href="https://wa.me/+923324903770">
                  <span className="fa fa-whatsapp"></span> +923324903770
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <h3 className="text-base text-gray-100 font-semibold text-center">
              Address
            </h3>
            <ul className="py-2 text-sm text-gray-100 text-center space-y-1">
              <li>
                <p>
                  Address: Main Rawalpindi Road, Seni Gumbat Bazar, Nearby PSO
                  "Petrol Station", Sign Board: Massgu Fancy Choice.
                </p>
              </li>
            </ul>
          </div>
        </section>
      </Container>
    </div>
  );
}

export default Home;
