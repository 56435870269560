import React, { useState } from "react";

const Header = ({ handleSearch,handleSearchBtn, cartItems }) => {
  const [searchValue, setSearchValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    handleSearch(value);
  };

  const handleChangeBtn = () => {
    handleSearchBtn(searchValue);
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  // const [imageError, setImageError] = useState(false);

  // const handleImageError = () => {
  //   setImageError(true);
  //   console.log('Image not available'); // You can replace this with any action you want
  // };

  return (
    <header className="bg-[#2c0c24] p-4 flex justify-between items-center md:px-24">
      <div className="flex items-center">
        <a href="/" className="text-white text-sm md:text-xl font-bold">
        <img
            src="/resources/logo.jpg"
            className="w-auto h-14 max-w-[142px]"
            alt="Logo"
          />
          {/* {!imageError ? (
          <img
            src="https:/massgu.com/resources/logo.png"
            className="h-16"
            onError={handleImageError}
            alt="Logo"
          />
        ) : (
          "MASSGU FANCY CHOICE"
        )} */}
        </a>
      </div>
      <div className="flex-grow mx-4 flex justify-center items-center bg-white rounded-2xl">
        <input
          type="text"
          id="mysearchbar"
          className="w-full px-4 py-1 md:py-2 rounded-2xl  text-customA outline-none"
          placeholder="Search in Massgu"
          value={searchValue}
          onChange={handleChange}
        />
        <span onClick={handleChangeBtn} className="fa fa-search py-1 md:py-2 rounded-xl cursor-pointer px-4 mx-1 text-customC bg-[#2c0c2440]"></span>
      </div>

      <div className="hidden sm:flex items-center text-xs lg:text-sm">
        <button className=" text-white px-2 py-2 rounded"><span className="fa fa-user"></span> Login</button>
        <span className="text-white mx-2">|</span>
        <button className="mr-2  text-white px-2 py-2 rounded">Signup</button>
        <a className=" text-white px-2 py-2 rounded space-x-1" href="/cart">
          <span className="fa fa-shopping-cart"></span>
          <span className="text-xs bg-white text-customA px-2 py-1 rounded-full">
            {cartItems.length}
          </span>
        </a>
      </div>

      <div className="sm:hidden relative">
        <button className="text-white" onClick={toggleDropdown}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
        {showDropdown && (
          <div className="absolute right-0 mt-2 w-48 bg-[#2c0c24] rounded-lg shadow-lg z-10 text-xs">
            <button className="block text-left w-full text-white px-4 py-2">
              <span className="fa fa-user"></span> Login
            </button>
            <button className="block text-left w-full text-white px-4 py-2">
              Signup
            </button>
            <a className="block text-left w-full text-white px-4 py-2 space-x-2" href="/cart">
              <span className="fa fa-shopping-cart"></span>
              <span className="text-xs bg-customA px-2 py-1 rounded-full">
                {cartItems.length}
              </span>
            </a>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
