import React, { useState, useEffect } from "react";
import Container from "./components/Container";
import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ReactPaginate from 'react-paginate';

function Orders() {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [orders, setOrders] = useState([]);
  const [Filteredorders, setFilteredOrders] = useState([]);
  const [allorders, setallOrders] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date()); // State to hold the selected date
  const [profitByDay, setProfitByDay] = useState(0); // State to hold profit by selected day
  const [dateInput, setDateInput] = useState("");
  const [product, setproduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  
  if (!isLoggedIn) {
    navigate("/login");
  }

  const fetchOrders = async (page) => {
    try {
      const response = await axios.get(`http://api.massgu.com/api/auth/orders`, { params: { page } });
      setOrders(response.data);
      setFilteredOrders(response.data);
    } catch (error) {
      setErrorMessage("No Order Available.");
    }
  };
  const fetchAllOrders = async (page) => {
    try {
      const response = await axios.get(`http://api.massgu.com/api/auth/orders`, { params: { page } });
      setallOrders(response.data); // Set orders with data from response
    } catch (error) {
      setErrorMessage("No Order Available.");
    }
  };
  useEffect(() => {
    fetchOrders(currentPage+1);
  }, [currentPage]);

  useEffect(() => {
    fetchAllOrders(100000);
  }, []);

  const handlePageClick = (data) => {
    console.log(data);
    setCurrentPage(data.selected); // Update currentPage state
  };

  useEffect(() => {
    fetchProducts();
  }, []);
  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        "http://api.massgu.com/api/auth/products"
      );
      setproduct(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleLogout = () => {
    navigate("/logout");
  };
  const handleSetting = () => {
    navigate("/dashboard/settings");
  };
  const handleManageProduct = () => {
    navigate("/dashboard/products");
  };
  const handleManageCategories = () => {
    navigate("/dashboard/cats");
  };
  const handleManageSubcategories = () => {
    navigate("/dashboard/subcats");
  };
  const handledashboard = () => {
    navigate("/dashboard");
  };
  const handleOrders = () => {
    navigate("/dashboard/orders");
  };

  const handlePDFDownload = (cartItems,totalPrice,uid) => {
    import('./splitting/generatePDFAdmin')
      .then(({ generatePDFAdmin }) => {
        return generatePDFAdmin(cartItems, totalPrice, uid);
      })
      .catch(err => {
        console.log("failed to load module");
      });
  };

  const handleAcceptOrder = async (orderId,pid,qt) => {
    try {
      await axios.put(`http://api.massgu.com/api/auth/orders`, { uid: orderId,pid: pid,quantity:qt });
      setErrorMessage("Order Accepted.");
      setOrders(prevOrders => prevOrders.map(order => order.id === orderId ? { ...order, status: "Accepted" } : order));
      fetchOrders();
    } catch (error) {
      setErrorMessage("Failed to accept the order Try Again Please.");
    }
  };
  const handleDeleteOrder = async (orderId) => {
    try {
      await axios.delete(`http://api.massgu.com/api/auth/orders`, { data: { uid: orderId } });
      setErrorMessage("Order Deleted.");
      setOrders(prevOrders => prevOrders.filter(order => order.id !== orderId));
      fetchOrders();
    } catch (error) {
      setErrorMessage("Failed to delete the order Try Again Please.");
    }
  };
  const getStockPrice = (products = product) => {
    let totalStock = 0;
    products.forEach((product) => {
        if (product && product.purchase_price && product.quantity) {
            totalStock += parseInt(product.purchase_price) * parseInt(product.quantity);
        }
    });
    return totalStock;
}

  const getTodayProfit = () => {
    const today = new Date().toLocaleDateString(); // Get today's date in format MM/DD/YYYY
    
    const todayProfit = allorders.reduce((totalProfit, order) => {
  
      // Check if the order is accepted and made today
      if (order.status === "Accepted" && new Date(order.created_at).toLocaleDateString() === today) {
        // Extract product information from the order
        const titles = order.titles.split(",");
        const prices = order.prices.split(",");
        const quantities = order.quantities.split(",");
  
        // Calculate profit for each product in the order
        const orderProfit = titles.reduce((orderTotal, title, index) => {
          const price = parseInt(prices[index]);
          const quantity = parseInt(quantities[index]);
          const profit = price * quantity;
          return orderTotal + profit;
        }, 0);
  
        return totalProfit + orderProfit; // Add order profit to total profit
      }
      return totalProfit;
    }, 0);
  
    return todayProfit;
  };

  const getThisMonthProfit = () => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1; // January is 0, so we add 1 to get the correct month
    const currentYear = today.getFullYear();
  
    const thisMonthProfit = allorders.reduce((totalProfit, order) => {
      // Extract month and year from the order's created_at date
      const orderDate = new Date(order.created_at);
      const orderMonth = orderDate.getMonth() + 1; // January is 0, so we add 1 to get the correct month
      const orderYear = orderDate.getFullYear();
  
      // Check if the order is accepted and matches the current month and year
      if (order.status === "Accepted" && orderMonth === currentMonth && orderYear === currentYear) {
        // Extract product information from the order
        const prices = order.prices.split(",");
        const quantities = order.quantities.split(",");
  
        // Calculate profit for each product in the order
        const orderProfit = prices.reduce((orderTotal, price, index) => {
          const quantity = parseInt(quantities[index]);
          const profit = parseInt(price) * quantity;
          return orderTotal + profit;
        }, 0);
  
        return totalProfit + orderProfit; // Add order profit to total profit
      }
      return totalProfit;
    }, 0);
  
    return thisMonthProfit;
  };
  
  const getThisYearProfit = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
  
    const thisYearProfit = allorders.reduce((totalProfit, order) => {
      // Extract year from the order's created_at date
      const orderDate = new Date(order.created_at);
      const orderYear = orderDate.getFullYear();
  
      // Check if the order is accepted and matches the current year
      if (order.status === "Accepted" && orderYear === currentYear) {
        // Extract product information from the order
        const prices = order.prices.split(",");
        const quantities = order.quantities.split(",");
  
        // Calculate profit for each product in the order
        const orderProfit = prices.reduce((orderTotal, price, index) => {
          const quantity = parseInt(quantities[index]);
          const profit = parseInt(price) * quantity;
          return orderTotal + profit;
        }, 0);
  
        return totalProfit + orderProfit; // Add order profit to total profit
      }
      return totalProfit;
    }, 0);
  
    return thisYearProfit;
  };

  const getAllTimesProfit = () => {
    const allTimesProfit = allorders.reduce((totalProfit, order) => {
      // Check if the order is accepted
      if (order.status === "Accepted") {
        // Extract product information from the order
        const prices = order.prices.split(",");
        const quantities = order.quantities.split(",");
  
        // Calculate profit for each product in the order
        const orderProfit = prices.reduce((orderTotal, price, index) => {
          const quantity = parseInt(quantities[index]);
          const profit = parseInt(price) * quantity;
          return orderTotal + profit;
        }, 0);
  
        return totalProfit + orderProfit; // Add order profit to total profit
      }
      return totalProfit;
    }, 0);
  
    return allTimesProfit;
  };
  // Function to calculate profit for the selected date
  const calculateProfitByDay = (date) => {
    const selectedDay = date.toLocaleDateString(); // Get selected date in format MM/DD/YYYY
    
    const profit = allorders.reduce((totalProfit, order) => {
      // Check if the order is accepted and made on the selected day
      if (order.status === "Accepted" && new Date(order.created_at).toLocaleDateString() === selectedDay) {
        // Extract product information from the order
        const prices = order.prices.split(",");
        const quantities = order.quantities.split(",");
  
        // Calculate profit for each product in the order
        const orderProfit = prices.reduce((orderTotal, price, index) => {
          const quantity = parseInt(quantities[index]);
          const profit = parseInt(price) * quantity;
          return orderTotal + profit;
        }, 0);
  
        return totalProfit + orderProfit; // Add order profit to total profit
      }
      return totalProfit;
    }, 0);
  
    return profit;
  };

  // Handler function when a date is selected from the calendar
  const handleDateChange = (date) => {
    setSelectedDate(date); // Update the selected date
    const profit = calculateProfitByDay(date); // Calculate profit for the selected date
    setProfitByDay(profit); // Update profit by day
  };

  const handleViewAccepted = () => {
    const acceptedOrders = orders.filter(order => order.status === "Accepted");
    setFilteredOrders(acceptedOrders);
  };
  const handleViewPending = () => {
    const pendingOrders = orders.filter(order => order.status === "pending");
    setFilteredOrders(pendingOrders);
  };
  const handleDateInputChange = (event) => {
    setDateInput(event.target.value); // Update the input value state
  };
  useEffect(() => {
    if (dateInput.trim() === "") {
      // If the input value is empty, show all orders
      setFilteredOrders(orders);
    } else {
      // Otherwise, filter orders based on the input date
      const filtered = allorders.filter(order => new Date(order.created_at).toLocaleDateString() === new Date(dateInput).toLocaleDateString());
      setFilteredOrders(filtered);
    }
  }, [dateInput, orders,allorders]);
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setErrorMessage("");
    }, 3000);
    return () => clearTimeout(timeout);
  }, [errorMessage]);
  

  return (
    <div className="bg-gray-100">
      <header className="py-4 px-24 bg-customC text-white">
        <a href="/dashboard">
          <h1 className="text-2xl font-bold text-center">My Admin</h1>
        </a>
      </header>
      <Container>
        <section className="grid grid-cols-12 gap-2">
          <div className="bg-white p-4 rounded-md col-span-3 md:col-span-2">
            <ul>
              <li>
                <button
                  onClick={handledashboard}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Dashboard
                </button>
              </li>
              <li>
                <button
                  onClick={handleManageProduct}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Products
                </button>
              </li>
              <li>
                <button
                  onClick={handleManageSubcategories}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Subcategories
                </button>
              </li>
              <li>
                <button
                  onClick={handleManageCategories}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Categories
                </button>
              </li>
              <li>
                <button className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm">
                  Manage Users
                </button>
              </li>
              <li>
                <button
                  onClick={handleOrders}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Order
                </button>
              </li>
              <li>
                <button
                  onClick={handleSetting}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Setting
                </button>
              </li>
              <li>
                <button
                  onClick={handleLogout}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
          <div className="bg-white p-4 rounded-md col-span-9 md:col-span-10">
            <div className="bg-customC text-white text-2xl font-bold text-center py-2 shadow">
              Manage Orders
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="col-span-full md:col-span-2 my-2 rounded-md border shadow p-2 overflow-scroll">
                {errorMessage && (
                  <p className="text-red-500">{errorMessage}</p>
                )}
                <table className="table-auto w-full border-collapse border border-customA">
                <caption className="w-full">
                  <button
                  className="bg-customA text-white py-1 px-2 m-1 text-xs rounded-md"
                  onClick={()=>{handleViewAccepted()}}
                  >View Accepted</button>
                  <button className="bg-customA text-white py-1 px-2 m-1 text-xs rounded-md"
                  onClick={()=>{handleViewPending()}}
                  >View Pending</button>

                  <input type="date" value={dateInput} onChange={handleDateInputChange} className="border px-3 py-0 rounded-md focus:outline-none focus:ring-2 focus:ring-customC" />
                  <p className="mx-2 text-slate-700 text-xs">Total Orders in Page: {Filteredorders.length}</p>
                </caption>
                  <thead>
                    <tr>
                      <th className="px-4 py-2 bg-customC text-gray-100 text-left">
                        Order Id
                      </th>
                      <th className="px-4 py-2 bg-customC text-gray-100 text-left">
                        Created on
                      </th>
                      <th className="px-4 py-2 bg-customC text-gray-100 text-left">
                        View/Download
                      </th>
                      <th className="px-4 py-2 bg-customC text-gray-100 text-left">
                        Action
                      </th>
                      <th className="px-4 py-2 bg-customC text-gray-100 text-left">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Filteredorders.map((order,index) => (
                      <tr key={order.id} className="border-customA hover:bg-customD">
                        <td className="border px-4 py-2">{order.uid}</td>
                        <td className="border px-4 py-2">{order.created_at}</td>
                        <td
                          className="border px-4 py-2 text-green-500 hover:text-blue-800 cursor-pointer"
                          onClick={() => handlePDFDownload(orders[index],order.total_amount ,order.uid)}
                        >
                          View/Download
                        </td>
                        <td
                          //className="border px-4 py-2 text-green-500 hover:text-blue-800 cursor-pointer"
                          className={`border px-4 py-2 ${
                            order.status === "Accepted" ? "text-slate-700" : "text-green-500 hover:text-blue-800 cursor-pointer"
                          }`}
                          onClick={order.status === "pending" ? () => handleAcceptOrder(order.uid, order.pid, order.quantities) : null}
                        >
                          {order.status === "pending" ? "Accept" : "Accepted"}
                        </td>
                        <td
                          className="border px-4 py-2 text-red-500 hover:text-blue-800 cursor-pointer"
                          onClick={() => handleDeleteOrder(order.uid)}
                        >
                          Delete
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="flex">
                  <ReactPaginate
                   className="flex space-x-4 py-2 mypagination"
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={allorders.length/10}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
              {/* Calendar */}
              <div className="col-span-full md:col-span-2 my-2 rounded-md border shadow p-2 overflow-scroll">
                <h2 className="text-lg font-semibold mb-2">Profit by Day</h2>
                <Calendar onChange={handleDateChange} value={selectedDate}
                className="mx-auto mycustomCalendar"/>
                {/* Display profit for the selected date */}
                <p className="border my-2 py-2 text-center text-sm">Profit for {selectedDate.toLocaleDateString()}: <strong className="text-green-500">{profitByDay}</strong></p>
              </div>
              <div
              className="col-span-full md:col-span-2 my-2 rounded-md border shadow p-2 grid grid-cols-2 md:grid-cols-4 gap-2">
                <div className="bg-customC p-2 rounded-md my-1 shadow col-span-full">
                  <p className="text-left text-white uppercase">
                    Stock Price
                  </p>
                  <h1 className="py-10 text-white text-xl font-semibold text-center">{getStockPrice()}</h1>
                </div>
                <div className="bg-customC p-2 rounded-md my-1 shadow">
                  <p className="text-left text-white uppercase">
                    Daily Profit
                  </p>
                  <h1 className="py-10 text-white text-xl font-semibold text-center">{getTodayProfit()}</h1>
                </div>
                <div className="bg-customC p-2 rounded-md my-1 shadow">
                  <p className="text-left text-white uppercase">
                    Monthly Profit
                  </p>
                  <h1 className="py-10 text-white text-xl font-semibold text-center">{getThisMonthProfit()}</h1>
                </div>
                <div className="bg-customC p-2 rounded-md my-1 shadow">
                  <p className="text-left text-white uppercase">
                    Yearly Profit
                  </p>
                  <h1 className="py-10 text-white text-xl font-semibold text-center">{getThisYearProfit()}</h1>
                </div>
                <div className="bg-customC p-2 rounded-md my-1 shadow">
                  <p className="text-left text-white uppercase">
                    All Times Profit
                  </p>
                  <h1 className="py-10 text-white text-xl font-semibold text-center">{getAllTimesProfit()}</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
}

export default Orders;
