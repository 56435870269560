import React, { useState } from "react";
import axios from "axios";
import Container from "./components/Container";
import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [dropDownClass, setdropDownClass] = useState("hidden");
  const [cats, setCategories] = useState([]);
  const [subcats, setSubCategories] = useState([]);
  const [pros, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);

  if (!isLoggedIn) {
    navigate("/login");
    return null;
  }

  const handleLogout = () => {
    navigate("/logout");
  };
  const handleOrders = () => {
    navigate("/dashboard/orders");
  };
  const handleSetting = () => {
    navigate("/dashboard/settings");
  };
  const handleManageProduct = () => {
    navigate("/dashboard/products");
  };
  const handleManageCategories = () => {
    navigate("/dashboard/cats");
  };
  const handleManageSubcategories = () => {
    navigate("/dashboard/subcats");
  };
  const handledashboard = () => {
    navigate("/dashboard");
  };
  const handleFilterDropDown = () => {
    if (dropDownClass === "hidden") {
      setdropDownClass("block");
    } else {
      setdropDownClass("hidden");
    }
  };
  // useEffect(() => {
  //   fetchData();
  // }, []);

  const fetchData = async () => {
    try {
      const catresponse = await axios.get(
        "http://api.massgu.com/api/auth/categories"
      );
      setCategories(catresponse.data);
      const subcatresponse = await axios.get(
        "http://api.massgu.com/api/auth/subcategories"
      );
      setSubCategories(subcatresponse.data);
      const proresponse = await axios.get(
        "http://api.massgu.com/api/auth/products"
      );
      setProducts(proresponse.data);
      const ordresponse = await axios.get("http://api.massgu.com/api/auth/orders");
      setOrders(ordresponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  fetchData();
  const uniqueBrand = Array.from(new Set(pros.map((product) => product.brand)));
  const acceptedOrd = orders.filter((ord) => ord.status === "Accepted");
  const pendingOrd = orders.filter((ord) => ord.status === "pending");

  return (
    <div className="bg-gray-100">
      <header className="py-4 px-24 bg-customC text-white">
        <a href="/dashboard">
          <h1 className="text-2xl font-bold text-center">My Admin</h1>
        </a>
      </header>
      <Container>
        <section className="grid grid-cols-12 gap-2">
          <div className="col-span-full py-2 md:hidden">
            <button
            onClick={() => handleFilterDropDown()}
            className="bg-customC text-white px-4 py-2 rounded-md">
              <span className="fa fa-navicon"></span>
            </button>
          </div>
          <div className={
              "bg-white p-4 rounded-md col-span-full md:col-span-2 md:block " +
              dropDownClass
            }>
            <ul>
              <li>
                <button
                  onClick={handledashboard}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Dashboard
                </button>
              </li>
              <li>
                <button
                  onClick={handleManageProduct}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Products
                </button>
              </li>
              <li>
                <button
                  onClick={handleManageSubcategories}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Subcategories
                </button>
              </li>
              <li>
                <button
                  onClick={handleManageCategories}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Categories
                </button>
              </li>
              <li>
                <button className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm">
                  Manage Users
                </button>
              </li>
              <li>
                <button
                  onClick={handleOrders}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Manage Order
                </button>
              </li>
              <li>
                <button
                  onClick={handleSetting}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Setting
                </button>
              </li>
              <li>
                <button
                  onClick={handleLogout}
                  className="bg-customC text-white py-1 px-2 w-full rounded-md my-2 text-xs md:text-sm"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
          <div className="bg-white p-4 rounded-md col-span-full md:col-span-10">
            <div className="bg-customC text-white text-2xl font-bold text-center py-2 shadow">
              Dashboard
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="bg-customC p-2 rounded-md my-1 shadow">
                <p className="text-left text-white uppercase">
                  total categories
                </p>
                <h1 className="py-10 text-white text-xl font-semibold text-center">{cats.length}</h1>
              </div>
              <div className="bg-customC p-2 rounded-md my-1 shadow">
                <p className="text-left text-white uppercase">
                  total subCategories
                </p>
                <h1 className="py-10 text-white text-xl font-semibold text-center">{subcats.length}</h1>
                </div>
              <div className="bg-customC p-2 rounded-md my-1 shadow">
                <p className="text-left text-white uppercase">
                  total Products
                </p>
                <h1 className="py-10 text-white text-xl font-semibold text-center">{pros.length}</h1>
                </div>
              <div className="bg-customC p-2 rounded-md my-1 shadow">
                <p className="text-left text-white uppercase">
                  total brands
                </p>
                <h1 className="py-10 text-white text-xl font-semibold text-center">{uniqueBrand.length}</h1>
                </div>
              <div className="bg-customC p-2 rounded-md my-1 shadow">
                <p className="text-left text-white uppercase">
                  total order
                </p>
                <h1 className="py-10 text-white text-xl font-semibold text-center">{orders.length}</h1>
                </div>
              <div className="bg-customC p-2 rounded-md my-1 shadow">
                <p className="text-left text-white uppercase">
                  Accepted orders
                </p>
                <h1 className="py-10 text-white text-xl font-semibold text-center">{acceptedOrd.length}</h1>
                </div>
              <div className="bg-customC p-2 rounded-md my-1 shadow col-span-2">
                <p className="text-left text-white uppercase">
                  pending orders
                </p>
                <h1 className="py-10 text-white text-xl font-semibold text-center">{pendingOrd.length}</h1>
                </div>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
}

export default Dashboard;
