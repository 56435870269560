import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import RealHome from "./RealHome";
import Login from "./Login";
import Dashboard from "./Dashboard";
import USetting from "./USetting";
import Product from "./Product";
import Register from "./Register";
import Logout from "./Logout";
import Cart from "./Cart";
import Orders from "./Orders";
//import PDFDocument from "./PDFDocument";
import "./font-awesome.css";
import ManageProducts from "./ManageProducts";
import ManageCategories from "./ManageCategories";
import ManageSubCategories from "./ManageSubCategories";

function App() {
  const [cartItems, setCartItems] = useState([]);
  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(storedCartItems);
  }, []);
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (item, uquantity = 1) => {
    const existingItemIndex = cartItems.findIndex(
      (cartItem) => cartItem.id === item.id
    );

    if (existingItemIndex !== -1) {
      const updatedCart = [...cartItems];
      updatedCart[existingItemIndex].quantity += uquantity;
      setCartItems(updatedCart);
    } else {
      setCartItems([...cartItems, { ...item, quantity: uquantity }]);
    }
  };

  const editCartItem = (index, action) => {
    const updatedCart = [...cartItems];

    switch (action) {
      case "increase":
        updatedCart[index].quantity += 1;
        break;
      case "decrease":
        if (updatedCart[index].quantity > 1) {
          updatedCart[index].quantity -= 1;
        }
        break;
      case "remove":
        updatedCart.splice(index, 1);
        break;
      default:
        break;
    }

    setCartItems(updatedCart);
  };

  const removeFromCart = (index) => {
    const updatedCart = [...cartItems];
    updatedCart.splice(index, 1);
    setCartItems(updatedCart);
  };

  // const calculateTotalPrice = (cartItems) => {
  //   let totalPrice = 0;
  //   cartItems.forEach((item) => {
  //     totalPrice += parseInt(item.current_price);
  //   });
  //   return totalPrice;
  // };

  //const totalPrice = calculateTotalPrice(cartItems);
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={<RealHome addToCart={addToCart} cartItems={cartItems} />}
        />
        <Route
          path="home/search"
          element={<Home addToCart={addToCart} cartItems={cartItems} />}
        />
        <Route
          path=":category/:subcategory/:product/:id"
          element={
            <Product
              addToCart={addToCart}
              cartItems={cartItems}
              editCartItem={editCartItem}
            />
          }
        />
        <Route path="login" element={<Login />} />
        <Route
          path="cart"
          element={
            <Cart
              removeFromCart={removeFromCart}
              editCartItem={editCartItem}
              cartItems={cartItems}
            />
          }
        />
        {/* <Route
          path="pdf"
          element={
            <PDFDocument cartItems={cartItems} totalPrice={totalPrice} />
          }
        />{" "} */}
        <Route path="logout" element={<Logout />} />
        <Route path="register" element={<Register />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="dashboard/products" element={<ManageProducts />} />
        <Route path="dashboard/subcats" element={<ManageSubCategories />} />
        <Route path="dashboard/cats" element={<ManageCategories />} />
        <Route path="dashboard/orders" element={<Orders />} />
        <Route path="dashboard/settings" element={<USetting />} />
      </Routes>
    </div>
  );
}

export default App;