import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FeatureSlider = ({ products, addToCart, cartItems, categories, subCategories }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Display four items in a row by default
    slidesToScroll: 1, // Scroll one item at a time
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024, // Adjust for tablets and smaller desktops
        settings: {
          slidesToShow: 4, // Display three items in a row
        },
      },
      {
        breakpoint: 768, // Adjust for mobile devices
        settings: {
          slidesToShow: 2, // Display two items in a row
        },
      },
    ],
  };

  // Filter products based on the condition
  const filteredProducts = products.filter((product) => parseInt(product.pwidth) === 1);
  const getCatfromPro = (subCatId, Allsubcat = subCategories, Allcat = categories) => {
    const getsubCat = Allsubcat.find((subcat) => subcat.id === subCatId);
    if (getsubCat) {
      const getCat = Allcat.find((cat) => cat.id === getsubCat.category_id);
      if (getCat) {
        return getCat.name;
      } else {
        return "Category Not Found";
      }
    } else {
      return "Subcategory Not Found";
    }
  };
  return (
    <div className="relative container mx-auto">
      <Slider {...settings}>
        {filteredProducts.map((product, index) => (
          <div key={index} className="p-1 md:p-1">
          <div className="rounded-md productItem shadow hover:shadow-2xl bg-[#F5BD02] p-1">
            <div className="bg-white">
              <div className=" max-h-48 overflow-hidden relative">
                <a href={`/${getCatfromPro(product.subcategory_id)}/${product.subcategory_name}/${product.title}/${product.id}`}>
                  {product.image.split(',:,').length > 1 && (
                    <div className="w-18 h-8 py-2 px-1 bg-black/50 text-white text-xs absolute">
                      {product.image.split(',:,').length - 1} More...
                    </div>
                  )}
                  {product.image.split(',:,').map((image, index) => {
                    if (index === 0) {
                      return (
                        <img
                          key={index}
                          className=" w-auto mx-auto p-0 min-h-46 text-white text-center"
                          src={`http://api.massgu.com/images/${image.trim()}`}
                          alt={`Product ${index + 1}`}
                        />
                      );
                    }
                    return null;
                  })}
                </a>
                <button
                  className={
                    cartItems.findIndex((cartItem) => cartItem.id === product.id) === -1
                      ? "absolute bottom-0 right-0 px-2 py-1 hover:px-3 hover:py-2 text-sm  bg-customC text-white rounded-full hover:bg-customA"
                      : "absolute bottom-0 right-0 px-2 py-1 hover:px-3 hover:py-2 text-sm rounded-full bg-gray-600 text-white text-center hover:bg-gray-700"
                  }
                  disabled={
                    cartItems.findIndex((cartItem) => cartItem.id === product.id) === -1
                      ? false
                      : true
                  }
                  onClick={() => {
                    addToCart(product);
                  }}
                  title="add to cart"
                >
                  <span className="fa fa-shopping-cart"></span>
                </button>
              </div>
              <div className="overflow-hidden">
                <div className="w-full h-16 overflow-hidden">
                  <a
                    href={`/${getCatfromPro(product.subcategory_id)}/${product.subcategory_name}/${product.title}/${product.id}`}
                    className="text-sm text-gray-700"
                    id="title"
                    title={product.title}
                  >
                    {product.title}
                  </a>
                </div>
                <div className="h-8 mt-1 flex space-x-1 flex-wrap justify-center items-center">
                  <div className="p-1 border border-cyan-500 text-center">
                    <p className="text-cyan-500 text-xs">Free Delivery</p>
                  </div>
                  {parseInt(((parseInt(product.old_price) - parseInt(product.current_price)) / parseInt(product.old_price)) * 100) > 1 ? (
                      <div className="p-1 border border-rose-500 text-center">
                      <p className="text-rose-500 text-xs">{parseInt(((parseInt(product.old_price) - parseInt(product.current_price)) / parseInt(product.old_price)) * 100)}% Off</p>
                      </div>
                  ) : null}
                </div>
                <div className="flex items-center space-x-1 py-1 h-12 overflow-hidden">
                  <h3 className="text-customC">
                    RS. {parseInt(product.current_price)}
                  </h3>
                  <del className="text-gray-400 text-xs">
                    RS. {parseInt(product.old_price)}
                  </del>
                  
                </div>
              </div>
              {/* <div className="text-center">
                
              </div> */}
            </div>
          </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default FeatureSlider;
