import React, { useState, useEffect} from "react";
import axios from "axios";
import QuillEditor from "../splitting/quillEditor";

function AddPro() {
  const [subCategories, setSubCategories] = useState([]);
  const [filteredSubcat, setFilteredSubcat] = useState([]);
  const [cat, setcat] = useState([]);
  const [getpro, setpro] = useState([]);
  const [selectedcategory, setSelectedcategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [title, setTitle] = useState("");
  const [ptype, setptype] = useState('');
  const [pheight, setpheight] = useState('');
  const [brand, setBrand] = useState("");
  const [images, setImages] = useState([]);
  //const [current_price, setCurrentPrice] = useState("");
  const [purchase_price, setPurchasePrice] = useState("");
  const [profit_price, setProfitPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [old_price, setOldPrice] = useState("");
  const [badge, setbadge] = useState("");
  const [badgeSymbol, setbadgeSymbol] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [output, setOutput] = useState("");

  useEffect(() => {
    fetchSubCategories();
  }, []);
  useEffect(() => {
    fetchProduct();
  }, []);
  useEffect(() => {
    fetchCat();
  }, []);
  useEffect(() => {
    if (selectedcategory) {
      const filter = subCategories.filter(
        (subcat) => subcat.category_id === selectedcategory
      );
      setFilteredSubcat(filter);
    }
  }, [selectedcategory, subCategories]);

  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(
        "http://api.massgu.com/api/auth/subcategories"
      );
      setSubCategories(response.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };
  const fetchProduct = async () => {
    try {
      const response = await axios.get(
        "http://api.massgu.com/api/auth/products"
      );
      setpro(response.data);
    } catch (error) {
      console.error("Error fetching Products:", error);
    }
  };
  const fetchCat = async () => {
    try {
      const response = await axios.get(
        "http://api.massgu.com/api/auth/categories"
      );
      setcat(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
    images.forEach((image, index) => {
      console.log(`image[${index}]`, image);
    });
  };
  const handleAddProduct = async () => {
    try {
      if (!selectedSubCategory || !title || !brand || !ptype || !pheight || !badge || !badgeSymbol || !images.length || !purchase_price || !profit_price || !old_price || !quantity || !output) {
        setAlertMessage("Please fill in all required fields.");
        return;
      }
      const formData = new FormData();
      formData.append("subcategory_id", selectedSubCategory.split(":")[0]);
      formData.append("subcategory_name", selectedSubCategory.split(":")[1]);
      formData.append("title", title);
      formData.append("brand", brand);
      formData.append('ptype', ptype);
      formData.append('pheight', pheight);
      formData.append('badge', badge);
      formData.append('badge_symbol', badgeSymbol);
      images.forEach((image, index) => {
        formData.append(`image[${index}]`, image);
      });
      formData.append("purchase_price", purchase_price);
      formData.append("profit_price", profit_price);
      formData.append("current_price", parseInt(parseInt(purchase_price)+(parseInt(profit_price)/100*parseInt(purchase_price))));
      formData.append("old_price", old_price);
      formData.append("quantity", quantity);
      formData.append("pdesc", output);
      const response = await axios.post(
        "http://api.massgu.com/api/auth/products",
        formData
      );

      if (response.status === 201) {
        setAlertMessage("Product created successfully");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error creating product:", error);
      setAlertMessage("Error creating product. Please try again.");
    }
  };

  const filterhandle = (selectedcat) => {
    setSelectedcategory(selectedcat);
    const filter = subCategories.filter(
      (subcat) => subcat.category_id === selectedcategory
    );
    setFilteredSubcat(filter);
    console.log(filteredSubcat);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      setAlertMessage("");
    }, 3000);

    return () => clearTimeout(timeout);
  }, [alertMessage]);

  return (
    <div
    className="col-span-full md:col-span-2 my-2 rounded-md border shadow p-2 overflow-scroll">
      <h1 className="text-customA font-bold">Add Products</h1>
      {alertMessage && <div className="alert text-customC">{alertMessage}</div>}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <select
          className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block text-gray-600 w-full"
          value={selectedcategory}
          onChange={(e) => {
            filterhandle(e.target.value);
          }}
        >
          <option value="" disabled>
            Select Category
          </option>
          {cat.map((mycat) => (
            <option key={mycat.id} value={`${mycat.id}`}>
              {mycat.name}
            </option>
          ))}
        </select>
        <select
          className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block text-gray-600 w-full"
          value={selectedSubCategory}
          onChange={(e) => {
            setSelectedSubCategory(e.target.value);
          }}
        >
          <option value="" disabled>
            Select Sub-Category
          </option>
          {filteredSubcat.map((subCategory) => (
            <option
              key={subCategory.id}
              value={`${subCategory.id}:${subCategory.name}`}
            >
              {subCategory.name}
            </option>
          ))}
        </select>
      </div>
      <input
        required={true}
        type="text"
        placeholder="Product Title"
        className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <input
          required={true} 
          type="text" 
          placeholder="Product Type" 
          className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
          value={ptype}
          list="type-list"
          onChange={(e) => setptype(e.target.value)}
        />
        <datalist id="type-list">
          {Array.from(new Set(getpro.map(product => product.ptype)))
          .map((suggestion, index) => (
            <option key={index} value={suggestion} />
          ))}
        </datalist>
        <input
          required={true}
          type="text"
          placeholder="Brand Name"
          className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
          value={brand}
          list="brands-list"
          onChange={(e) => setBrand(e.target.value)}
        />
        <datalist id="brands-list">
          {Array.from(new Set(getpro.map(product => product.brand)))
          .map((suggestion, index) => (
            <option key={index} value={suggestion} />
          ))}
        </datalist>
      </div>
      <div className="flex items-end justify-center gap-2">
        <select
          className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block text-gray-600 w-full"
          onChange={(e) => {
            setbadgeSymbol(e.target.value);
          }}
        >
          <option value="" disabled selected>
            select badge icon
          </option>
          <option value="fa fa-star">
            star
          </option>
          <option value="fa fa-truck">
            truck
          </option>
          <option value="fa fa-paperclip">
            paper clip
          </option>
          <option value="fa fa-bell">
            bell
          </option>
          <option value="fa fa-info-circle">
            info
          </option>
          <option value="fa fa-gift">
            gift
          </option>
          <option value="fa fa-tag">
            tag
          </option>
          <option value="fa fa-warning">
            warning
          </option>
          <option value="fa fa-check">
            check mark
          </option>
          <option value="fa fa-shopping-cart">
            shopping cart
          </option>
          <option value="fa fa-heart">
            heart
          </option>
          <option value="fa fa-clock-o">
            clock
          </option>
          <option value="fa fa-rocket">
            rocket
          </option>
          <option value="fa fa-trophy">
            trophy
          </option>
        </select>
        <input
          type="text"
          placeholder="Badge Name"
          className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
          value={badge}
          list="badge-list"
          onChange={(e) => setbadge(e.target.value)}
        />
        <datalist id="badge-list">
          {Array.from(new Set(getpro.map(product => product.badge)))
          .map((suggestion, index) => (
            <option key={index} value={suggestion} />
          ))}
        </datalist>
      </div>
      <input
        required={true}
        type="file"
        placeholder="image"
        className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
        onChange={handleImageChange}
        multiple
      />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
        <input
          required={true}
          type="number"
          placeholder="Buying Price"
          className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
          value={purchase_price}
          onChange={(e) => setPurchasePrice(e.target.value)}
        />
        <input
          required={true}
          type="number"
          placeholder="Profit"
          className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
          value={profit_price}
          onChange={(e) => setProfitPrice(e.target.value)}
        />
        <input
          required={true}
          type="number"
          placeholder="Old Price"
          className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
          value={old_price}
          onChange={(e) => setOldPrice(e.target.value)}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <input
          required={true}
          type="number"
          placeholder="Quantity"
          className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
        <input
          required={true}
          type="number"
          placeholder="Stock Alert"
          className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
          value={pheight}
          onChange={(e) => setpheight(e.target.value)}
        />
      </div>
      <QuillEditor setOutput={setOutput} preDefinedContent={"Description"} />
      
      <button
        className="py-1 px-2 bg-customA text-white rounded-md"
        onClick={handleAddProduct}
      >
        Add
      </button>
    </div>
  );
}

export default AddPro;