import React, { useState, useEffect } from "react";
import axios from "axios";

function ListSubcat() {
  const [subcategories, setSubcategories] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editSubcategory, setEditSubcategory] = useState(null);
  const [subcategoryName, setSubcategoryName] = useState("");
  const [parentCategories, setParentCategories] = useState([]);
  const [selectedParentCategory, setSelectedParentCategory] = useState("");

  useEffect(() => {
    fetchSubcategories();
    fetchParentCategories();
  }, []);

  const fetchSubcategories = async () => {
    try {
      const subcategoriesResponse = await axios.get(
        "http://api.massgu.com/api/auth/subcategories"
      );
      const subcategoriesData = subcategoriesResponse.data;

      const categoriesResponse = await axios.get(
        "http://api.massgu.com/api/auth/categories"
      );
      const categoriesData = categoriesResponse.data;

      const mergedSubcategories = subcategoriesData.map((subcategory) => {
        const parentCategory = categoriesData.find(
          (category) => category.id === subcategory.category_id
        );
        return {
          ...subcategory,
          categoryName: parentCategory ? parentCategory.name : "",
        };
      });

      setSubcategories(mergedSubcategories); 
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const fetchParentCategories = async () => {
    try {
      const response = await axios.get(
        "http://api.massgu.com/api/auth/categories"
      );
      setParentCategories(response.data);
    } catch (error) {
      console.error("Error fetching parent categories:", error);
    }
  };

  const handleDeleteSubcategory = async (subcategoryId) => {
    try {
      const response = await axios.delete(
        `http://api.massgu.com/api/auth/subcategories/${subcategoryId}`
      );

      if (response.status === 204) {
        console.log("Subcategory deleted successfully");
        fetchSubcategories();
      }
    } catch (error) {
      console.error("Error deleting subcategory:", error);
    }
  };

  const handleEditSubcategory = (subcategory) => {
    setEditSubcategory(subcategory);
    setSubcategoryName(subcategory.name);
    setSelectedParentCategory(subcategory.category_id);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditSubcategory(null);
    setSubcategoryName("");
    setSelectedParentCategory("");
    setEditModalOpen(false);
  };

  const handleEditSubcategorySubmit = async (event) => {
    event.preventDefault();
    try {

      const response = await axios.put(
        `http://api.massgu.com/api/auth/subcategories/${editSubcategory.id}`,
        {
          name: subcategoryName,
          category_id: selectedParentCategory,
        }
      );

      if (response.status === 200) {
        console.log("Subcategory updated successfully");
        fetchSubcategories();
        handleCloseEditModal();
      }
    } catch (error) {
      console.error("Error updating subcategory:", error);
    }
  };

  return (
    <div>
      <table className="table-auto w-full border-collapse border border-customA">
        <thead>
          <tr>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Parent Category
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Name
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Delete
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Edit
            </th>
          </tr>
        </thead>
        <tbody>
          {subcategories.map((subcategory) => (
            <tr
              key={subcategory.id}
              className="border-customA hover:bg-customD"
            >
              <td className="border px-4 py-2">{subcategory.categoryName}</td>
              <td className="border px-4 py-2">{subcategory.name}</td>
              <td
                className="border px-4 py-2 text-red-500  hover:text-blue-800 cursor-pointer"
                onClick={() => handleDeleteSubcategory(subcategory.id)}
              >
                Delete
              </td>
              <td
                className="border px-4 py-2 text-green-500  hover:text-blue-800 cursor-pointer"
                onClick={() => handleEditSubcategory(subcategory)}
              >
                Edit
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editModalOpen && (
        <div
        className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div
          className="bg-white p-2 rounded-lg shadow-lg w-full md:w-1/2 space-y-4">
            <div
            className="bg-customC text-white text-2xl font-bold text-center py-2 shadow">
              Edit Subcategory
            </div>
            <form onSubmit={handleEditSubcategorySubmit}>
              <input
                type="text"
                placeholder="Subcategory Name"
                className="border py-1 px-2 rounded-md outline-customA bg-white my-2 inline-block w-full"
                value={subcategoryName}
                onChange={(e) => setSubcategoryName(e.target.value)}
              />
              <select
                value={selectedParentCategory}
                onChange={(e) => setSelectedParentCategory(e.target.value)}
                className="border py-1 px-2 rounded-md outline-customA bg-white my-2 inline-block w-full"
              >
                <option value="">Select Parent Category</option>
                {parentCategories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              <div className="flex justify-around items-center">
                <button
                  className="px-2 py-1 bg-customC text-white rounded-md hover:bg-customA"
                  title="Cancel"
                  onClick={handleCloseEditModal}
                >
                  Cancel
                </button>
                <button
                  className="px-2 py-1 bg-customC text-white rounded-md hover:bg-customA"
                  title="Save"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListSubcat;
