import React, { useState, useEffect } from "react";
import axios from "axios";
import QuillEditor from "../splitting/quillEditor";

function ListPro() {
  const [products, setProducts] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [badge, setbadge] = useState("");
  const [badgeSymbol, setbadgeSymbol] = useState("");
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [productid, setproductid] = useState(null);
  const [productTitle, setProductTitle] = useState("");
  const [brand, setBrand] = useState("");
  const [image, setImage] = useState(null);
  const [pwidth, setpwidth] = useState(null);
  //const [currentPrice, setCurrentPrice] = useState("");
  const [profitPrice, setProfitPrice] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [oldPrice, setOldPrice] = useState("");
  const [ptype, setPtype] = useState('');
  const [pweight, setPweight] = useState('');
  const [pdesc, setPdesc] = useState("");
  const [pheight, setpheight] = useState("");
  const [quantity, setQuantity] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [output, setOutput] = useState("");
  const [editImg, setEditImg] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);
  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        "http://api.massgu.com/api/auth/products"
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchSubCategories();
  }, []);

  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(
        "http://api.massgu.com/api/auth/subcategories"
      );
      setSubCategories(response.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const response = await axios.delete(
        `http://api.massgu.com/api/auth/products/${productId}`
      );

      if (response.status === 204) {
        console.log("Product deleted successfully");
        fetchProducts();
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const handleEditProduct = (xproduct, subcategoryId, subcategoryName) => {
    setproductid(xproduct.id);
    setProductTitle(xproduct.title);
    setBrand(xproduct.brand);
    //setCurrentPrice(xproduct.current_price);
    setPtype(xproduct.ptype);
    setpwidth(xproduct.pwidth);
    setProfitPrice(xproduct.profit_price);
    setPurchasePrice(xproduct.purchase_price);
    setOldPrice(xproduct.old_price);
    setPdesc(xproduct.pdesc);
    setPweight(xproduct.pweight);
    setpheight(xproduct.pheight);
    setbadge(xproduct.badge);
    setQuantity(xproduct.quantity);
    setbadgeSymbol(xproduct.badge_symbol);
    setSelectedSubCategory(`${subcategoryId}:${subcategoryName}`);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setproductid(null);
    setProductTitle("");
    setBrand("");
    setImage("");
    //setCurrentPrice("");
    setOldPrice("");
    setpwidth(null);
    setProfitPrice("");
    setPurchasePrice("");
    setPtype('');
    setpheight('');
    setPweight('');
    setPdesc("");
    setbadge("");
    setQuantity("");
    setbadgeSymbol("");
    setSelectedSubCategory("");
    setEditModalOpen(false);
  };
  const handleEditProductSubmit = async (event) => {
    event.preventDefault();
    try {
      setPdesc(output);
      const formData = new FormData();
      formData.append("subcategory_id", selectedSubCategory.split(":")[0]);
      formData.append("subcategory_name", selectedSubCategory.split(":")[1]);
      formData.append("title", productTitle);
      formData.append("brand", brand);
      formData.append("pwidth", pwidth);
      formData.append("image", image);
      formData.append("current_price", parseInt(parseInt(purchasePrice)+(parseInt(profitPrice)/100*parseInt(purchasePrice))));
      formData.append("profit_price", profitPrice);
      formData.append("purchase_price", purchasePrice);
      formData.append('ptype', ptype);
      formData.append('badge', badge);
      formData.append('badge_symbol', badgeSymbol);
      formData.append("old_price", oldPrice);
      formData.append("pdesc", output);
      formData.append("pweight", pweight);
      formData.append("pheight", pheight);
      formData.append("quantity", quantity);

      const response = await axios.put(
        `http://api.massgu.com/api/auth/products/${productid}`,
        formData,
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        console.log(response.message);
        fetchProducts();
        handleCloseEditModal();
      }
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleEditProductImageSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      if(newImages.length>0){
        newImages.forEach((image) => {
          formData.append("image[]", image);
        });
      }
      else{
        editImg.forEach((image) => {
          formData.append("image[]", image);
        });
      }
  
      const response = await axios.post(
        `http://api.massgu.com/api/auth/products/images/${productid}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
  
      if (response.status === 201) {
        console.log(response.data.message);
        fetchProducts();
        setNewImages([]);
        setImageModalOpen(false);
      }
    } catch (error) {
      console.error("Error updating product images:", error);
    }
  };

  const handleImageChange = (e) => {
    setNewImages([...e.target.files]);
    console.log(newImages);
  };
  function removeImageAtIndex(indexToRemove) {
    setEditImg(prevImages => {
      const updatedImages = [...prevImages];
      updatedImages.splice(indexToRemove, 1);
      return updatedImages;
    });
  }
  function coverImageAtIndex(indexToCover) {
    setEditImg(prevImages => {
      const updatedImages = [...prevImages];
      const imageToCover = updatedImages[indexToCover];
      updatedImages.splice(indexToCover, 1);
      updatedImages.unshift(imageToCover);
      return updatedImages;
    });
  }
  
  const handleViewImages = (productId) => {
    const product = products.find((item) => item.id === productId);
    setImage(product.image);
    const x = product.image.split(',:,').map((img, index) => (img.trim()));
    setEditImg(x);
    setproductid(productId)
    setImageModalOpen(true);
  };

  const filterViewAll = () => {
    fetchProducts();
  };

  const filterViewWarning = () => {
    const filter = products.filter(product => product.quantity != null && product.quantity <= parseInt(product.pheight) | product.quantity <= 0);
    setProducts(filter);
  };
  

  return (
    <div>
      <button
      className="bg-customA text-white py-1 px-2 m-1 text-xs rounded-md"
      onClick={()=>{filterViewAll()}}
      >View All</button>
      <button className="bg-customA text-white py-1 px-2 m-1 text-xs rounded-md"
      onClick={()=>{filterViewWarning()}}
      >view Out of Stock</button>
      <table className="table-auto w-full border-collapse border border-customA">
        <thead>
          <tr>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Image
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Title
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Category
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Brand
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Current Price
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Old Price
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Product Type
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Quantity
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Stock Alert
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Featuring
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Just For You
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Delete
            </th>
            <th className="px-4 py-2 bg-customC text-gray-100 text-left">
              Edit
            </th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id} id={"product_id_"+product.id} className="border-customA hover:bg-customD">
              <td className="border lg:px-4 lg:py-2 min-w-[150px] text-center">
              {/* {product.image.split(',:,').map((image, index) => (
                <img key={index} src={`http://api.massgu.com/images/${image.trim()}`} alt={`Product ${index + 1}`} />
              ))} */}
              {product.image.split(',:,').map((image, index) => {
                if (index === 0) {
                  return (
                  <img
                  key={index}
                  src={`http://api.massgu.com/images/${image.trim()}`}
                  alt={`Product ${index + 1}`}/>
                  );
                }
                return null;
                })}
                <button
                  className="p-1 bg-black/50 text-xs hover:bg-black/75 text-white rounded-md"
                  onClick={() => {
                    handleViewImages(product.id);
                  }}
                >
                  Change Images
                </button>
              </td>
              <td className="border px-4 py-2">{product.title}</td>
              <td className="border px-4 py-2">{product.subcategory_name}</td>
              <td className="border px-4 py-2">{product.brand}</td>
              <td className="border px-4 py-2">{product.current_price}</td>
              <td className="border px-4 py-2">{product.old_price}</td>
              <td className="border px-4 py-2">{product.ptype}</td>
              <td className="border px-4 py-2">
                {product.quantity}
              </td>
              <td className="border px-4 py-2">
                {product.pheight}
              </td>
              <td className="border px-4 py-2">{parseInt(product.pwidth)===1?"Featuring":"No"}</td>
              <td className="border px-4 py-2">{parseInt(product.pweight)===1?"JustForYou":"No"}</td>
              <td
                className="border px-4 py-2 text-red-500  hover:text-blue-800 cursor-pointer"
                onClick={() => handleDeleteProduct(product.id)}>
                Delete
              </td>
              <td
                className="border px-4 py-2 text-green-500  hover:text-blue-800 cursor-pointer"
                onClick={() =>
                  handleEditProduct(
                    product,
                    product.subcategory_id,
                    product.subcategory_name
                  )}>
                Edit
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {imageModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-2 rounded-lg shadow-lg w-full md:w-1/2 space-y-4s">
            {/* Modal content */}
            <div className="bg-customC text-white text-2xl font-bold text-center py-2 shadow">
              Current Images
            </div>
            <form
              onSubmit={handleEditProductImageSubmit}
            >
              <div className="flex flex-wrap gap-2">
                {editImg.map((img, index) => (
                  <div className="relative w-40 border border-customA">
                  <button
                    type="button"
                    className="absolute px-1 bg-rose-700 text-white"
                    onClick={() => removeImageAtIndex(index)}
                  >
                    x
                  </button>

                  {index===0?<button type="button" className="absolute px-1 bg-green-700 text-white right-0">cover</button>:
                  <button
                  type="button"
                  onClick={() => coverImageAtIndex(index)}
                  className="absolute px-1 bg-rose-700 text-white right-0"
                >
                  cover
                </button>}
                  <img
                  key={index}
                  src={`http://api.massgu.com/images/${img.trim()}`}
                  alt={`Product ${index + 1}`}/>
                  </div>
                ))}
              </div>
              <div className="overflow-hidden text-ellipsis whitespace-nowrap text-center">
                <input type="file" multiple onChange={handleImageChange}
                className="my-2"/>
              </div>
              <div className="flex justify-around items-center">
                <button
                  className="px-2 py-1 bg-customC text-white rounded-md hover:bg-customA"
                  title="Cancel"
                  onClick={() => setImageModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-2 py-1 bg-customC text-white rounded-md hover:bg-customA"
                  title="Save"
                  type="submit"
                >
                  Save
                </button>
              </div>
          </form>
          </div>
        </div>
      )}

      {editModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-2 rounded-lg shadow-lg w-full md:w-1/2 space-y-4s">
            <div className="bg-customC text-white text-2xl font-bold text-center py-2 shadow">
              Edit Product
            </div>
            <form
              onSubmit={handleEditProductSubmit}
              className="md:text-xs text-xs"
            >
              <select
                className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block text-gray-600 w-full"
                value={selectedSubCategory}
                onChange={(e) => setSelectedSubCategory(e.target.value)}
              >
                {subCategories.map((subCategory) => (
                  <option
                    key={subCategory.id}
                    value={`${subCategory.id}:${subCategory.name}`}
                  >
                    {subCategory.name}
                  </option>
                ))}
              </select>
              <input
                type="text"
                placeholder="Product Title"
                className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
                value={productTitle}
                onChange={(e) => setProductTitle(e.target.value)}
              />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <input 
                  type="text" 
                  placeholder="Product Type" 
                  className="border py-1 px-2 rounded-md outline-customA bg-white my-2 inline w-full"
                  value={ptype}
                  onChange={(e) => setPtype(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Brand"
                  className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                <input
                  type="number"
                  placeholder="Buying Price"
                  className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
                  value={purchasePrice}
                  onChange={(e) => setPurchasePrice(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="Profit"
                  className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
                  value={profitPrice}
                  onChange={(e) => setProfitPrice(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="Old Price"
                  className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
                  value={oldPrice}
                  onChange={(e) => setOldPrice(e.target.value)}
                />
              </div>
              <div className="flex items-end justify-center">
                <select
                  className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block text-gray-600 w-full"
                  onChange={(e) => {
                    setbadgeSymbol(e.target.value);
                  }}
                  value={badgeSymbol}
                >
                  <option value="" disabled>
                    select badge icon
                  </option>
                  <option value="fa fa-star">
                    star
                  </option>
                  <option value="fa fa-truck">
                    truck
                  </option>
                  <option value="fa fa-paperclip">
                    paper clip
                  </option>
                  <option value="fa fa-bell">
                    bell
                  </option>
                  <option value="fa fa-info-circle">
                    info
                  </option>
                  <option value="fa fa-gift">
                    gift
                  </option>
                  <option value="fa fa-tag">
                    tag
                  </option>
                  <option value="fa fa-warning">
                    warning
                  </option>
                  <option value="fa fa-check">
                    check mark
                  </option>
                  <option value="fa fa-shopping-cart">
                    shopping cart
                  </option>
                  <option value="fa fa-heart">
                    heart
                  </option>
                  <option value="fa fa-clock-o">
                    clock
                  </option>
                  <option value="fa fa-rocket">
                    rocket
                  </option>
                  <option value="fa fa-trophy">
                    trophy
                  </option>
                </select>
                <input
                  type="text"
                  placeholder="Badge Name"
                  className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
                  value={badge}
                  list="badge-list"
                  onChange={(e) => setbadge(e.target.value)}
                />
                <datalist id="badge-list">
                  {Array.from(new Set(products.map(product => product.badge)))
                  .map((suggestion, index) => (
                    <option key={index} value={suggestion} />
                  ))}
                </datalist>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <input
                  type="number"
                  placeholder="quantity"
                  className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="Stock Alert"
                  className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block w-full"
                  value={pheight}
                  onChange={(e) => setpheight(e.target.value)}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <select
                    className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block text-gray-600 w-full"
                    onChange={(e) => {
                      setpwidth(e.target.value);
                    }}
                    selected={pwidth}
                  >
                    <option value="" disabled>
                      Featuring
                    </option>
                    <option value={0}>
                      No
                    </option>
                    <option value={1}>
                      Yes
                    </option>
                  </select>
                  <select
                    className="border py-1 px-2 rounded-md outline-customA bg-white my-2 block text-gray-600 w-full"
                    onChange={(e) => {
                      setPweight(e.target.value);
                    }}
                    selected={pwidth}
                  >
                    <option value="" disabled>
                      Just for you
                    </option>
                    <option value={0}>
                      No
                    </option>
                    <option value={1}>
                      Yes
                    </option>
                  </select>
                </div>
              <QuillEditor setOutput={setOutput} preDefinedContent={pdesc} />
              <div className="flex justify-around items-center">
                <button
                  className="px-2 py-1 bg-customC text-white rounded-md hover:bg-customA"
                  title="Cancel"
                  onClick={handleCloseEditModal}
                >
                  Cancel
                </button>
                <button
                  className="px-2 py-1 bg-customC text-white rounded-md hover:bg-customA"
                  title="Save"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListPro;
